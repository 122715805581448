function parseConfigStrToObj(str) {
  return str.split('&').map((item) => {
    const temp = item.split(':');
    const code = parseInt(temp[0], 10);
    return {
      code: isNaN(code) ? temp[0] : code,
      desc: temp[1],
    };
  });
}

// 订单支付类型
const orderPayMethodStr = '0:无需支付&1:线下支付&2:在线支付&3:在线支付&4:会员支付&5:微信支付&6:支付宝支付&7:微信支付&8:支付宝&9:会员支付&10:微信扫码&11:支付宝扫码&12:翼支付&13:支付宝支付&14:建行支付'; // &7:微信小微&8:支付宝小微
export const orderPayMethod = parseConfigStrToObj(orderPayMethodStr);

// 订单收获状态配置
const isPredictStateStr = '1:送货上门&2:送货上门&3:到店自提';
export const isPredictState = parseConfigStrToObj(isPredictStateStr);

// 订单支付状态配置
const orderPayStatusStr = '1:未支付&2:已支付&3:已退款&4:已完成';
export const orderPayStatus = parseConfigStrToObj(orderPayStatusStr);

// 订单打印状态配置
const orderPrintStateStr = '1:未打印&2:已打印&3:打印失败';
export const orderPrintState = parseConfigStrToObj(orderPrintStateStr);

// 1-会员消费，2.会员退款，3-平台加积分，4.平台减积分，5.结转会员积分，6.会员兑换积分商品，7.完善会员资料，8.完成订单评价，9会员充值送积分, 10获得积分, 11扣减积分, 12注册赠送积分, 13邀请赠送积分, 14登录赠送积分,15开通会员赠送积分, 16收藏店铺赠积分，17下单积分抵扣扣减, 18每日签到赠积分，19连续签到赠积分
const integralTypeStr = '1:用户消费&2:用户退款&3:平台加积分&4:平台减积分&5:结转用户积分&6:用户兑换积分商品&7:完善用户资料&8:完成订单评价&9:用户充值送积分&12:注册赠送积分&13:邀请赠送积分&14:登录赠送积分&15:开通储值卡赠送积分&16:收藏店铺赠积分&17:下单积分抵扣扣减&18:每日签到赠积分&19:连续签到赠积分&20:买单记录退款&21:返还兑换积分&22:退款返还积分&23:抽奖消耗积分&24:抽奖获得积分';
export const integralType = parseConfigStrToObj(integralTypeStr);

// 会员余额状态
// '会员在线充值', '下单扣减储值', '撤单返还储值', '手动增加储值', '手动扣减储值', '结转会员余额'
const accountTypeStr = '1:用户在线充值&2:下单扣减储值&3:撤单返还储值&4:手动增加储值&5:手动扣减储值&6:结转储值余额&7:商家添加储值&8:到店买单消费&9:跑腿订单打赏&10:（外卖）订单退款&11:跑腿订单退款&12:到店订单退款&14:购买红包套餐&15:分销订单下单&16:分销订单退款';
export const accountType = parseConfigStrToObj(accountTypeStr);

const platMoneyDetailTypeStr = '1:外送订单收入&2:商家资金提现&3:撤销订单退款&4:平台主动下发资金&5:团购验券入账&6:平台手动补贴&7:平台手动扣减&8:扣除到付资金&9:买单订单收入&10:退还到付资金&11:买单订单退款&12:订单部分退款&13:订单部分退款退回&14:配送费用扣减&15:商家资金充值&16:配送费用返还&17:分销验券收入';
export const platMoneyDetailType = parseConfigStrToObj(platMoneyDetailTypeStr);
export const platMoneyDetailTypeReduce = [1, 5, 6, 9, 10, 11, 13, 15, 16, 17]; // 显示减号或颜色区分的配置项

// 用户端资金明细类型
const userMoneyTypeStr = '1:在线充值余额&2:下单扣减余额&3:撤单返还余额&4:手动增加储值&5:手动扣减储值&6:结转会员余额&7:商家添加储值&8:下单扣减余额&9:跑腿订单打赏&10:（外卖）订单退款&11:跑腿订单退款&12:到店订单退款&13:部分退款返还余额&14:购买红包套餐&15:下单扣减余额&16:撤单返还余额';
export const userMoneyType = parseConfigStrToObj(userMoneyTypeStr);
export const userMoneyTypeReduce = [2, 5, 8, 9, 14, 15]; // 自己干明细的减号type

//  [].map((item, index) => (index + 1) + ':' + item ).join('&')

const capitalMoneyTypeStr = '1:消费返利&2:推荐分润&3:会员提现&4:消费分润退款&5:推荐分润退款&6:资金分润退款';
export const capitalMoneyType = parseConfigStrToObj(capitalMoneyTypeStr);
export const capitalMoneyTypeReduce = [3, 4, 5, 6]; // 自己干明细的减号type

const orderRefoundStateStr = '1:申请中&2:已驳回&3:退款完成&4:取消申请&5:无需退款&6:部分退款成功&7:部分退款失败&8:手动退款完成&9:部分手动退款完成&10:撤销订单系统未退款';
export const orderRefoundState = parseConfigStrToObj(orderRefoundStateStr);
