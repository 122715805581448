import validateList from './validateRule';


function getRuleItemByName(name) {
  return validateList.find((validateItem) => validateItem.name === name);
}

function getNameDescription(key) {
  const nameToDescriptionDict = {
    name: '名称',
  };
  return nameToDescriptionDict[key] || '';
}

function validateItemByRuleName(value, keyDesc, rule) {
  const prevalidateItem = getRuleItemByName(rule.validatorName);
  const validateRst = prevalidateItem.validateHandler(value, rule.params);
  if (validateRst !== true) {
    if (rule.validatorMsg) return rule.validatorMsg;
    return `${keyDesc || ''}${validateRst}` || '参数错误';
  }
  return false;
}


class DataValidator {
  static parseKeyNameDesc(ruleStr, keyName) {
    let nameDesc = '';
    if (ruleStr.indexOf('@') > -1) {
      const items = ruleStr.split('@');
      // eslint-disable-next-line no-param-reassign
      ruleStr = items[0];
      nameDesc = items[1];
    } else if (keyName) {
      nameDesc = getNameDescription(keyName);
    }
    return {
      nameDesc,
      ruleStr,
    };
  }

  static getRuleName(ruleFullStr) {
    const { ruleStr } = DataValidator.parseKeyNameDesc(ruleFullStr);
    if (ruleStr.indexOf(':') > -1) {
      const list = ruleStr.split(':');
      return list[0];
    }
    return ruleStr;
  }

  static getRuleParams(ruleStr) {
    if (ruleStr.indexOf(':') > -1) {
      const list = ruleStr.split(':');
      return list.slice(1);
    }
    return [];
  }

  static parseRule(ruleStr = '') {
    if (!ruleStr || ruleStr.indexOf('/') > -1) {
      throw new Error('校验规则参数异常');
    }
    const ruleStrList = ruleStr.split('|');
    for (let i = 0; i < ruleStrList.length; i += 1) {
      const ruleItem = ruleStrList[i];
      const prevalidateItem = getRuleItemByName(DataValidator.getRuleName(ruleItem));
      if (!prevalidateItem) {
        throw new Error(`校验器不存在: ${ruleItem}`);
      }
      const preValidate = prevalidateItem.pre;
      if (preValidate) {
        ruleStrList.splice(i, 0, preValidate);
        i += 1;
      }
    }
    return ruleStrList.map((rule) => {
      const nameParseEnd = DataValidator.parseKeyNameDesc(rule);
      return {
        validatorName: DataValidator.getRuleName(nameParseEnd.ruleStr),
        validatorMsg: nameParseEnd.nameDesc,
        params: DataValidator.getRuleParams(nameParseEnd.ruleStr),
      };
    });
  }

  constructor(value, rulesStr, keyDesc) {
    this.value = value;
    this.keyDesc = keyDesc;
    const ruleList = DataValidator.parseRule(rulesStr);
    this.isRequire = Boolean(ruleList.find((rule) => rule.validatorName === 'require'));
    // .filter((rule) => rule.validatorName !== 'require')
    this.ruleList = ruleList;
  }

  check() {
    let errorMsg;
    const item = this.ruleList.find((ruleItem) => {
      const res = validateItemByRuleName(this.value, this.keyDesc, ruleItem);
      if (res) {
        errorMsg = res;
        return true;
      }
      return false;
    });
    this.errorMsg = errorMsg;
    // console.log('DataValidator -> check -> item', item);
    return !item;
  }
}

export class Validator {
  constructor(ruleList) {
    this.keyList = ruleList.map((str) => {
      const item = str.split('/');
      return {
        key: item[0],
        ruleStr: item[1] || '',
        keyDesc: item[2] || '',
      };
    });
  }

  check(dataObj) {
    let errorMsg = '';
    const item = this.keyList.find((keyItem) => {
      const dataValidator = new DataValidator(dataObj[keyItem.key], keyItem.ruleStr, keyItem.keyDesc);
      const result = dataValidator.check();
      if (!result) {
        errorMsg = dataValidator.errorMsg;
      }
      return !result;
    });
    this.errorMsg = errorMsg;
    return !item;
  }
}



export default function (value, args1, args2) {
  let validatorInstance;
  if (typeof args1 === 'string') {
    const ruleStr = args1;
    const keyDesc = args2;
    validatorInstance = new DataValidator(value, ruleStr, keyDesc);
  } else if (Array.isArray(args1)) {
    const rulesList = args1;
    validatorInstance = new Validator(rulesList);
  } else {
    throw new Error('规则参数异常， 请先查阅文档');
  }
  const result = validatorInstance.check(value);
  if (result) {
    return Promise.resolve();
  } else {
    return Promise.reject(validatorInstance.errorMsg);
  }
}
