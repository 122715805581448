import { loadCatch } from '@base/utils/utils';
import MainContainer from '../views/main/MainContainer.vue';

const routes = [
  {
    path: '/',
    redirect: { name: 'AgentIndex' },
  },
  {
    path: '/main',
    name: 'Main',
    component: MainContainer,
    children: [
      {
        path: 'agentIndex',
        name: 'AgentIndex',
        title: '首页',
        icon: 'sx-icon-shouye',
        component: () => import(/* webpackChunkName: "index" */ '../views/shopIndex/shopIndex.vue').catch(loadCatch),
      },
      {
        path: 'noticeDetail',
        name: 'NoticeDetail',
        show: false,
        component: () => import(/* webpackChunkName: "index" */ '../views/notice/detail.vue').catch(loadCatch),
      },
      {
        path: 'bill',
        name: 'Bill',
        title: '账单',
        icon: 'sx-icon-zu1280-copy',
        component: () => import(/* webpackChunkName: "bill" */ '../views/bill/index.vue').catch(loadCatch),
        children: [
          {
            path: 'operatingWallet',
            name: 'OperatingWallet',
            title: '运营钱包',
            component: () => import(/* webpackChunkName: "bill" */ '../views/bill/operatingWallet/operatingWallet.vue').catch(loadCatch),
          },
          {
            path: 'shareProfitsDetail',
            name: 'ShareProfitsDetail',
            title: '外卖分润',
            component: () => import(/* webpackChunkName: "bill" */ '../views/bill/shareProfitsDetail.vue').catch(loadCatch),
          },
          {
            path: 'groupBuyingBill',
            name: 'GroupBuyingBill',
            title: '团购分润',
            component: () => import(/* webpackChunkName: "bill" */ '../views/bill/groupBuyingBill.vue').catch(loadCatch),
          },
          {
            path: 'withdrawalRecord',
            name: 'WithdrawalRecord',
            title: '提现记录',
            component: () => import(/* webpackChunkName: "bill" */ '../views/bill/withdrawalRecord.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'agnetUser',
        name: 'agnetUser',
        title: '代理商',
        icon: 'sx-icon-zu1280-copy',
        component: () => import(/* webpackChunkName: "agnetUser" */ '../views/agnetUser/index.vue').catch(loadCatch),
        children: [
          {
            path: 'AgnetUserLsit',
            name: 'AgnetUserLsit',
            title: '代理商列表',
            component: () => import(/* webpackChunkName: "bill" */ '../views/agnetUser/AgnetUserList.vue').catch(loadCatch),
          },

        ],
      },
      {
        path: 'business',
        name: 'Business',
        title: '商户',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/index.vue').catch(loadCatch),
        children: [
          {
            path: 'reviewed',
            name: 'Reviewed',
            title: '商户列表',
            component: () => import(/* webpackChunkName: "business" */ '../views/business/reviewed.vue').catch(loadCatch),
          },
          // {
          //   path: 'waitAudit',
          //   name: 'WaitAudit',
          //   title: '待核单/驳回商户',
          //   component: () => import(/* webpackChunkName: "business" */ '../views/business/waitAudit.vue'),
          // },
          // {
          //   path: 'addBusiness',
          //   name: 'AddBusiness',
          //   title: '添加商户',
          //   show: false,
          //   component: () => import(/* webpackChunkName: "business" */ '../views/business/addBusiness.vue'),
          // },
          {
            path: 'editBusiness',
            name: 'EditBusiness',
            title: '',
            show: false,
            component: () => import(/* webpackChunkName: "business" */ '../views/business/addBusiness.vue'),
          },
          // {
          //   path: 'agentAccountAudit',
          //   name: 'AgentAccountAudit',
          //   title: '代理商户审核',
          //   component: () => import(/* webpackChunkName: "business" */ '../views/business/agentAccountAudit.vue'),
          // },
        ],
      },
      {
        path: 'order',
        name: 'Order',
        title: '订单',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/index.vue').catch(loadCatch),
        children: [
          {
            path: 'bussinessOrder',
            name: 'BussinessOrder',
            title: '外卖订单',
            icon: 'sx-icon-zu1281-copy',
            component: () => import(/* webpackChunkName: "bussinessOrder" */ '../views/order/bussinessOrder.vue').catch(loadCatch),
          },
          {
            path: 'runOrder',
            name: 'RunOrder',
            title: '跑腿订单',
            icon: 'sx-icon-zu1281-copy',
            component: () => import(/* webpackChunkName: "runOrder" */ '../views/order/runOrder.vue').catch(loadCatch),
          },
          {
            path: 'groupBuyingOrder',
            name: 'GroupBuyingOrder',
            title: '团购订单',
            icon: 'sx-icon-zu1281-copy',
            component: () => import(/* webpackChunkName: "groupBuyingOrder" */ '../views/order/groupBuyingOrder.vue').catch(loadCatch),
          },
          {
            path: 'abnormalOrder',
            name: 'AbnormalOrder',
            title: '异常订单',
            icon: 'sx-icon-zu1281-copy',
            component: () => import(/* webpackChunkName: "abnormalOrder" */ '../views/order/abnormalOrder.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'advertising',
        name: 'advertising',
        title: '广告',
        icon: 'sx-icon-jizhangben',
        component: () => import(/* webpackChunkName: " advertising" */ '../views/advertising/index.vue').catch(loadCatch),
        show: false,
        children: [
          {
            path: 'advertisingList',
            name: 'AdvertisingList',
            title: '广告列表',
            show: false,
            component: () => import(/* webpackChunkName: "order" */ '../views/advertising/advertisingList.vue').catch(loadCatch),
          },
          {
            path: 'advertisingQualification',
            name: 'AdvertisingQualification',
            title: '资质上传',
            show: false,
            component: () => import(/* webpackChunkName: "order" */ '../views/advertising/advertisingQualification.vue').catch(loadCatch),
          },
          {
            path: 'createAd',
            name: 'CreateAd',
            title: '创建广告',
            show: false,
            component: () => import(/* webpackChunkName: "order" */ '../views/advertising/createAd.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'delivery',
        name: 'Delivery',
        title: '配送团队管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "delivery" */ '../views/delivery/index.vue').catch(loadCatch),
        children: [
          {
            path: 'rider',
            name: 'rider',
            title: '骑手管理',
            component: () => import(/* webpackChunkName: "rider" */ '../views/rider/rider.vue').catch(loadCatch),
          },
          {
            path: 'ongoingOrder',
            name: 'ongoingorder',
            title: '进行中订单',
            component: () => import(/* webpackChunkName: "rider" */ '../views/ongoingOrder/ongoingOrder.vue').catch(loadCatch),
          },
          {
            path: 'riderdetail',
            name: 'riderdetail',
            show: false,
            title: '查看详情',
            component: () => import(/* webpackChunkName: "rider" */ '../views/rider/riderDetail.vue').catch(loadCatch),
          },
          {
            path: 'riderposition',
            name: 'riderposition',
            show: false,
            title: '骑手定位',
            component: () => import(/* webpackChunkName: "rider" */ '../views/rider/riderPosition.vue').catch(loadCatch),
          },
          {
            path: 'orderDispatch',
            name: 'OrderDispatch',
            title: '订单调度',
            component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/delivery/orderDispatch.vue').catch(loadCatch),
          },
          {
            path: 'orderQuery',
            name: 'OrderQuery',
            title: '订单查询',
            component: () => import(/* webpackChunkName: "orderQuery" */ '../views/delivery/orderQuery.vue').catch(loadCatch),
          },
          // {
          //   path: 'setting',
          //   name: 'setting',
          //   title: '配送设置',
          //   component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/delivery/setting.vue').catch(loadCatch),
          // },
          {
            path: 'takeout',
            name: 'takeout',
            title: '外卖配送设置',
            component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/setDelivery/takeout.vue').catch(loadCatch),
          },
          {
            path: 'errand',
            name: 'errand',
            title: '跑腿业务配置',
            component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/setDelivery/errand.vue').catch(loadCatch),
          },
          {
            path: 'meituanDelivery',
            name: 'MeituanDelivery',
            title: '美团配送设置',
            component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/setDelivery/meituanDelivery.vue').catch(loadCatch),
          },
          // {
          //   path: 'pickUpSetting',
          //   name: 'pickUpSetting',
          //   title: '取件业务设置',
          //   component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/delivery/pickUpSetting.vue').catch(loadCatch),
          // },
          // {
          //   path: 'runSetting',
          //   name: 'runSetting',
          //   title: '跑腿业务设置',
          //   component: () => import(/* webpackChunkName: "orderDispatch" */ '../views/delivery/runSetting.vue').catch(loadCatch),
          // },
        ],
      },
      {
        path: 'shopGroup',
        name: 'ShopGroup',
        title: '商圈',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "business" */ '../views/shopGroup/index.vue').catch(loadCatch),
        children: [
          {
            path: 'shopGroupList',
            name: 'shopGroupList',
            title: '商圈管理',
            component: () => import(/* webpackChunkName: "shopGroup" */ '../views/shopGroup/shopGroupList.vue').catch(loadCatch),
          },
          {
            path: 'shopGroupDistribution',
            name: 'ShopGroupDistribution',
            title: '商圈布局',
            component: () => import(/* webpackChunkName: "shopGroup" */ '../views/shopGroup/shopGroupDistribution.vue').catch(loadCatch),
          },

        ],
      },
      {
        path: 'groupBuying',
        name: 'GroupBuying',
        title: '团购',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "groupBuying" */ '../views/groupBuying/index.vue').catch(loadCatch),
        children: [
          {
            path: 'groupBuyingList',
            name: 'GroupBuyingList',
            title: '团购列表',
            component: () => import(/* webpackChunkName: "groupBuying" */ '../views/groupBuying/groupBuyingList.vue').catch(loadCatch),
          },
          {
            path: 'groupBuyingGoods',
            name: 'GroupBuyingGoods',
            title: '新增商品',
            show: false,
            component: () => import(/* webpackChunkName: "groupBuying" */ '../views/groupBuying/groupBuyingGoods.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'schoolGroup',
        name: 'SchoolGroup',
        title: '校园商圈',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "schoolGroup" */ '../views/schoolGroup/index.vue').catch(loadCatch),
        children: [
          {
            path: 'schoolGrouplayout',
            name: 'schoolGrouplayout',
            title: '首页布局',
            component: () => import(/* webpackChunkName: "schoolGrouplayout" */ '../views/schoolGroup/schoolGrouplayout.vue').catch(loadCatch),
          },
          {
            path: 'schoolGroupShop',
            name: 'schoolGroupShop',
            title: '商圈商家',
            component: () => import(/* webpackChunkName: "schoolGroupShop" */ '../views/schoolGroup/schoolGroupShop.vue').catch(loadCatch),
          },
          {
            path: 'recommendSettings',
            name: 'recommendSettings',
            title: '推荐设置',
            component: () => import(/* webpackChunkName: "recommendSettings" */ '../views/schoolGroup/recommendSettings/index.vue').catch(loadCatch),
          },
          {
            path: 'couponList',
            name: 'CouponList',
            title: '优惠券',
            component: () => import(/* webpackChunkName: "couponList" */ '../views/schoolGroup/couponSetting/couponList.vue').catch(loadCatch),
          },
          {
            path: 'addCoupon',
            name: 'AddCoupon',
            title: '新增优惠券',
            show: false,
            component: () => import(/* webpackChunkName: "addCoupon" */ '../views/schoolGroup/couponSetting/addCoupon.vue').catch(loadCatch),
          },
          {
            path: 'couponDetail',
            name: 'CouponDetail',
            title: '优惠券详情',
            show: false,
            component: () => import(/* webpackChunkName: "couponDetail" */ '../views/schoolGroup/couponSetting/couponDetail.vue').catch(loadCatch),
          },
          {
            path: 'applyRider',
            name: 'applyRider',
            title: '申请骑手',
            component: () => import(/* webpackChunkName: "applyRider" */ '../views/schoolGroup/applyRider.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'notice',
        name: 'Notice',
        title: '公告',
        icon: 'sx-icon-lujing455-copy',
        component: () => import(/* webpackChunkName: "index" */ '../views/notice/list.vue').catch(loadCatch),
      },
      {
        path: '/',
        redirect: { name: 'AgentIndex' },
      },
      {
        path: '*',
        redirect: { name: 'AgentIndex' },
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue').catch(loadCatch),
  },
  {
    path: '/findPassword',
    name: 'FindPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/findPassword.vue').catch(loadCatch),
  },

];

function deepBuildMenu(routeList) {
  return routeList.map((routeItem) => {
    const menuItem = {
      ...routeItem,
      component: undefined,
    };
    if (routeItem.children && routeItem.children.length > 0) {
      menuItem.children = deepBuildMenu(menuItem.children);
    }

    return menuItem;
  }).filter((item) => (!['/', '*'].includes(item.path) && item.show !== false));
}

function deepfindMenu(routeList, path, pathList) {
  return routeList.find((routeItem) => {
    const menuItem = {
      path: routeItem.path,
      title: routeItem.title,
      icon: routeItem.icon,
    };
    if (routeItem.children && routeItem.children.length > 0) {
      const end = deepfindMenu(routeItem.children, path, pathList);
      if (end) {
        pathList.push(menuItem);
      }
      return Boolean(end);
    }
    if (routeItem.path === path) {
      pathList.push(menuItem);
    }

    return routeItem.path === path;
  });
}

export const getRouteMenu = () => deepBuildMenu(routes.find((item) => item.path === '/main').children);

export const getRouteByPath = (path) => {
  const routePath = [];
  deepfindMenu(routes.find((item) => item.path === '/main').children, path, routePath);
  return routePath.reverse();
};

export default routes;
