// 提示音播放工具

let audioElem;
const audioPlayTaskList = [];

function creatAudioElem(src) {
  return new Promise((resolve) => {
    let audio;
    if (audioElem) {
      if (!src) {
        resolve(audioElem);
        return;
      }
      audio = audioElem;
    } else {
      audio = document.createElement('audio');
      audio.autobuffer = true;
      audioElem = audio;
    }
    audio.src = src;
    audio.addEventListener('canplaythrough', () => {
      resolve(audio);
    }, false);
  });
}

function creatOneAudioElem(src, endCallback) {
  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.autobuffer = true;
    audio.src = src;
    audio.addEventListener('canplaythrough', () => {
      resolve(audio);
    }, false);
    audio.addEventListener('error', (e) => {
      reject(e);
    });
    audio.addEventListener('emptied', (e) => {
      reject(e);
    });
    audio.addEventListener('ended', () => {
      endCallback && endCallback();
    }, false);
  });
}

let audioPlayEndHandler = null;
export const setAudioPlayEndHandler = (handler) => {
  audioPlayEndHandler = handler;
};

export const playAudio = (src) => new Promise((resolve, reject) => {
  creatAudioElem(src).then((audio) => audio.play()).catch((e) => {
    // console.table(e);
    // NotAllowedError
    reject(e.name);
  }).then(() => {
    resolve();
  });
});

export const stopAudio = () => {
  if (audioElem) {
    audioElem.currentTime = 0;
    audioElem.pause();
  }
};

let AudioTaskPlaying = false;
function beginPlayTask() {
  console.log('before play audio', AudioTaskPlaying, audioPlayTaskList);
  if (AudioTaskPlaying) return;
  const currentPlayTask = audioPlayTaskList[0];
  if (!currentPlayTask) return;
  console.log('begin play audio', currentPlayTask);
  creatOneAudioElem(currentPlayTask.url, () => {
    console.log('played audio', currentPlayTask);
    // 修改状态为播放结束
    currentPlayTask.status = 3;
    const shiftItem = audioPlayTaskList.shift();
    AudioTaskPlaying = false;
    audioPlayEndHandler && audioPlayEndHandler(shiftItem, audioPlayTaskList);
    beginPlayTask();
  }).then((audio) => {
    console.log('playing audio', currentPlayTask);
    // 修改状态为播放中
    currentPlayTask.status = 2;
    return audio.play();
  }).catch((e) => {
    console.log(e);
    // NotAllowedError
    // reject(e.name);
    currentPlayTask.errorBack(e.name || '', () => {
      AudioTaskPlaying = false;
      beginPlayTask();
    });
  });
  AudioTaskPlaying = true;
}

/**
 * 播放url
 * 循环次数 num
 */
export const addPlayAudioTask = (url, num = 1, errorBack, flag) => {
  console.log('task add', url, num);
  if (!url) return;
  if (num <= 0) return;
  new Array(num).fill('').forEach(() => {
    audioPlayTaskList.push({
      url,
      num,
      flag,
      status: 1,
      errorBack,
    });
  });
  beginPlayTask();
};
