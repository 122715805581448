// 不进行拦截的请求
const excludeInterceptorsUrl = [];

export const CustomConfigEnum = {
  // 请求过程中开启loading
  SHOW_LOADING: 1,
  // 自动弹出消息提示
  SHOW_MESSAGE: 2,
  // 丢弃响应的第一层数据
  DATA_REJECT: 8,
};

export const requestCommonInterceptor = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, {
    getUserToken, getBaseUrl, getParamsData,
  }) {
    const { url } = config;
    if (excludeInterceptorsUrl.includes(url)) {
      // window.location.href = '/#/login';
      return Promise.reject(new Error('认证 token 已过期，请重新登录'));
    }
    // console.error(config);
    // eslint-disable-next-line
    config.baseURL = getBaseUrl();
    const userToken = getUserToken();
    if (userToken) {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    if (!config.params) {
      // eslint-disable-next-line
      config.params = {};
    }
    // eslint-disable-next-line
    config.params = {
      ...config.params,
      ...(getParamsData() || {}),
    };

    return config;
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { messageError } = options;
    messageError(error.message);
    return Promise.reject(error);
  },
};


export const responseNeedLoginInterceptor = {
  onFulfilled(response, { toWxLogin }) {
    // console.log(response, 'response');
    // configKey
    const { data } = response;
    if ([1002, 401].includes(data.code)) {
      toWxLogin(data);
      return Promise.reject(data);
    }
    return response;
  },
};

export const responseNeedBindTelInterceptor = {
  onFulfilled(response, { toBindTel }) {
    const { data } = response;
    if ([1005, 401].includes(data.code)) {
      toBindTel(data);
      return Promise.reject(data);
    }
    return response;
  },
};

export const responseDataInterceptor = {
  onFulfilled(response, { messageError, messageSuccess, loading }) {
    const { config, data } = response;
    const { customConfig } = config;

    let code = data.code;

    // console.log(data, 'data', response);
    // message(data.msg);

    if (code < 0) {
      code = 1001;
    }

    if (customConfig & CustomConfigEnum.SHOW_LOADING) {
      loading(false);
    }

    if ([1001, 400, 500].includes(code)) {
      if (customConfig & CustomConfigEnum.SHOW_MESSAGE) {
        messageError(data.msg);
      }
      return Promise.reject({
        ...data,
        response,
      });
    }

    if ((customConfig & CustomConfigEnum.SHOW_MESSAGE)
        && [1000, 200].includes(code)
        && config.method === 'post') {
      messageSuccess(data.msg);
    }


    if (customConfig & CustomConfigEnum.DATA_REJECT) {
      return data.data;
    }
    return data;
  },
  onRejected(error, { messageError }) {
    const { customConfig = 0 } = error.config || {};
    if (!window.navigator.onLine || (error.message && error.message.includes('timeout'))) {
      const noNetWorkError = '网络开小差了~';
      if (customConfig & CustomConfigEnum.SHOW_MESSAGE) {
        messageError(noNetWorkError);
      }
      return Promise.reject({
        code: 1001,
        msg: noNetWorkError,
        error,
      });
    }

    return Promise.reject(error);
  },
};
