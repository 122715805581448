
import Vue from 'vue';
import { Message } from 'element-ui';
import { $p_uploadImageBase64, $sp_createPlatPicture } from '@/api/common';
import { loadAxiosInterceptors } from '@base/utils/request';
import ToolsUI from '@base/ui';
import {
  requestCommonInterceptor,
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
} from '@base/utils/request/axiosInterceptors';
import router from '@/router';
import store from '@/store';
import FileUploader from '@base/utils/file/FileUploader';
import { getBaseUrl } from '@/config';
import {
  getUserToken,
} from '@/global/tokenManager';

loadAxiosInterceptors([requestCommonInterceptor], [
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
], {
  router,
  store,
  messageSuccess: Message.success,
  messageError: Message.error,
  loading(loadingFlag) {
    console.log(loadingFlag);
    // if (loadingFlag) {
    //   Toast.loading();
    // }
  },
  toWxLogin() {
    router.push('/login');
  },
  toBindTel() {
    router.push('/login');
  },
  getUserToken,
  getBaseUrl,
  getParamsData() {
    return {};
  },
});

FileUploader.setFileUploadApis({
  uploadApiFn: $p_uploadImageBase64,
  addPlatPicture: $sp_createPlatPicture,
  loadingFn(loadingFlag) {
    console.log(loadingFlag);
    // if (loadingFlag) {
    //   Toast.loading();
    // } else {
    //   Toast.clear();
    // }
  },
});

Vue.use(ToolsUI);

