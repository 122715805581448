function deepClone(val) {
  return JSON.parse(JSON.stringify(val));
}

function parseSearchField(field, searchParams) {
  const newFields = field.map((fieldItem) => {
    if (typeof fieldItem === 'string') {
      const [prop, placeholder, type = 'text'] = fieldItem.split('|');
      return {
        prop,
        placeholder,
        type,
      };
    }
    const newFieldItem = JSON.parse(JSON.stringify(fieldItem));
    if (newFieldItem.getOptions) {
      newFieldItem.optionsData = [];
      const endRes = newFieldItem.getOptions(newFieldItem);
      if (endRes.then) {
        endRes.then((resOptions) => {
          newFieldItem.optionsData = resOptions;
        });
      } else {
        newFieldItem.optionsData = endRes;
      }
    }
    return newFieldItem;
  });

  newFields.forEach((fileItem) => {
    let fieldDefaultData = '';
    if (typeof fileItem.default === 'function') {
      fieldDefaultData = fileItem.default();
    } else if (fileItem.default !== undefined && fileItem.default !== null) {
      fieldDefaultData = fileItem.default;
    }
    // eslint-disable-next-line
    searchParams[fileItem.prop] = fieldDefaultData;
  });

  return newFields;
}

function parseTableField(columnList) {
  const parsedColumnsList = columnList.map((columnItem) => {
    if (typeof columnItem === 'string') {
      const [prop, placeholder, type = 'text', filterFunName] = columnItem.split('|');
      return {
        prop,
        placeholder,
        type,
        filterFunName,
        align: 'center',
        isFixed: false,
      };
    }
    const newFieldItem = deepClone(columnItem);
    if (newFieldItem.getOptions) {
      newFieldItem.optionsData = [];
      const endRes = newFieldItem.getOptions(newFieldItem);
      if (endRes.then) {
        endRes.then((resOptions) => {
          newFieldItem.optionsData = resOptions;
        });
      } else {
        newFieldItem.optionsData = endRes;
      }
    }
    if (!newFieldItem.align) {
      newFieldItem.align = 'center';
    }
    return newFieldItem;
  });

  return parsedColumnsList;
}

function parseSearchOperate(operateList) {
  return operateList.map((item) => {
    const [label, eventName] = item.split('|');
    return {
      label,
      eventName,
    };
  });
}

export default function (pageOptions) {
  const parsedOptions = {};
  parsedOptions.searchParams = deepClone(pageOptions.defaultData.searchParams || {});
  parsedOptions.searchFields = parseSearchField(pageOptions.search.fields, parsedOptions.searchParams);
  parsedOptions.searchOperate = parseSearchOperate(pageOptions.search.operate);
  parsedOptions.tableFields = parseTableField(pageOptions.table.columns);
  parsedOptions.methods = pageOptions.methods;
  parsedOptions.getTableData = pageOptions.getTableData;
  return parsedOptions;
}
