export default {
  state: {
    menu: [],
  },
  mutations: {
    menu(state, menu) {
      state.menu = [...menu];
    },
  },
};
