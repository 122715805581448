<template>
  <div class="export-tips-wrapper" v-if="visible">
    <div class="tips-mask">
      <div class="tips-main">
        <div class="tips-title">提示</div>
        <div class="tips-content">
          <p>您申请导出的数据，将会被添加到任务列表中。导出任务完成后可前往【下载中心】点击下载。</p>
          <p class="theme-color">请确认是否导出数据？</p>
          <div class="img-box">
            <img src="/img/statistics/export-data-tips.png" alt="">
          </div>
        </div>
        <div class="tips-footer">
          <el-button class="tips-btn" @click="cancle">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import api from '@/api/api';
import { exportDataConfig } from '@/config/exportData';

export default {
  data() {
    return {
      exportParams: {},
      comName: '',
      visible: false,
    };
  },
  methods: {
    cancle() {
      this.visible = false;
    },

    // 请求导出数据接口
    determine() {
      this.visible = false;
      this.exportDataRequest();
    },
    exportDataRequest() {
      console.log(exportDataConfig, this.comName);
      const url = exportDataConfig[this.comName];
      console.log('url', url);
      console.log('exportParams', this.exportParams);
      api.get(url, this.exportParams).then(() => {
        store.commit('changeExportTips', true);
        this.$message({ message: '导出成功，请到下载中心下载', type: 'success' });
        localStorage.setItem('ExportDataTips', true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.export-tips-wrapper{
 .tips-mask{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 100;
  .tips-main{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20%;
    height: 270px;
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    .tips-title{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .tips-content{
      color: #666666;
      p:first-child{
        padding: 16px 0;
      }
      .img-box{
        width: 100%;
        padding: 10px 0;
        img{
          width: 100%;
        }
      }
    }
    .tips-footer{
      text-align: center;
      .tips-btn{
        margin-right: 10%;
      }
    }
  }
 }
}
</style>
