import rolePermissions from '@/config/rolePermissions';
import { deepClone } from '@base/utils';

let authList = localStorage.getItem('shop_user_auth_str');
try {
  authList = JSON.parse(authList);
} catch (e) {
  authList = [];
}

if (!authList) authList = [];

export default {
  state: {
    authList,
    isRoot: false,
    rolePermissions: rolePermissions.datas,
    authCache: {},
  },
  getters: {
    Auth_getUserAuth(state) {
      if (state.isRoot) {
        return -1;
      }

      return deepClone(state.authList);
    },
    Auth_isFolder: (state) => (key) => {
      const menu = state.rolePermissions.findIndex((item) => {
        if (parseInt(item.title.key, 10) === key) {
          return true;
        }
        return false;
      });
      return menu;
    },
    Auth_checkAuth: (state, getters) => (key) => {
      const auth = getters.Auth_getUserAuth;
      if (auth === -1) {
        return true;
      }
      const floderIndex = getters.Auth_isFolder(key);
      let hasPermiss;
      if (floderIndex !== -1) {
        const { list } = state.rolePermissions[floderIndex];
        hasPermiss = list.some((item) => {
          if (auth.indexOf(parseInt(item.key, 10)) !== -1) return true;
          return false;
        });
        state.authCache[key] = hasPermiss;
        return hasPermiss;
      }
      hasPermiss = auth.indexOf(key) !== -1;
      state.authCache[key] = hasPermiss;
      return hasPermiss;
    },
  },
  mutations: {
    Auth_setAuthList(state, auth) {
      const list = auth.split(',');
      state.authList = list.map((item) => parseInt(item, 10));
      state.authCache = {};
      console.log('auth', state.authList);
      localStorage.setItem('shop_user_auth_str', JSON.stringify(state.authList));
    },
    Auth_setIsRoot(state, is) {
      state.isRoot = is;
      state.authCache = {};
      localStorage.setItem('shop_user_auth_str', -1);
    },
  },
};
