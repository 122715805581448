import api from './api';
// 获取地区信息
export const $sg_AllArea = () => api.get('/api/common/getAllArea');
// -----------上传零点到it-----------
export const $$p_uploadForKindeditor = ({ params, data }) => api.postNoRejectNoTips('/alone/v1/Upload/uploadImageBase64', data, { params });
// 图库管理-添加平台图库
export const $sp_createPlatPicture = (params) => api.postNoTips('/platform/v1/PlatGallery/createPlatPicture', params);
// 获取平台图库
export const $sg_getGoodsPictures = (params) => api.get('/mall/v1/GoodsManage/getGoodsPictures', params);

export const $p_sendCaptchaSms = (params) => api.post('/j/sms/sendCaptchaSms', params);

// 图库管理-添加平台图库
export const $p_createPlatPicture = (params) => api.postNoTips('/platform/v1/PlatGallery/createPlatPicture', params);

// 上传图片
export const $p_uploadImageBase64 = ({ params, data }) => api.postNoRejectNoTips('/alone/v1/Upload/uploadImageBase64', data, { params });

// 获取平台信息
export const $sg_getSpreadMainInfo = () => api.getNoTips('/platform/v1/SpreadInfo/getSpreadMainInfo');

// 获取代理商的商圈信息
export const $sg_getTradingArea = () => api.getNoTips('/sssk/api/agent/biz/tradingAreaShop/getTradingArea');
