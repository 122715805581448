<template>
  <div class="page-title-comp">
    <h3>{{title}}</h3>
  </div>
</template>
<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
.page-title-comp {
  h3 {
    font-size: 18px;
    color: #313333;
    margin: 0;
    display: inline-block;
    vertical-align: bottom;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 14px;
  }
}
</style>
