export const favorable = [ // 菜品标签
  {
    label: '正常',
    value: 1,
  },
  {
    label: '促销',
    value: 2,
  },
  {
    label: '招牌',
    value: 3,
  },
  {
    label: '特惠',
    value: 4,
  },
  {
    label: '新品',
    value: 5,
  },
  {
    label: '推荐',
    value: 6,
  },
];

export const temp = '';
