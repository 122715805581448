import * as fieldConfig from './fieldConfig';

export const UPLOAD_DOMAIN = process.env.VUE_APP_UPLOAD_DOMAIN;

export const getBaseUrl = () => process.env.VUE_APP_API_DOMAIN;

export const picConfig = {};

export const getWapBaseUrl = () => process.env.VUE_APP_WAP_DOMAIN;


export function getDescFromCode(code, field) {
  const configs = fieldConfig[field];
  if (!configs) return '';
  const configItem = configs.find((item) => item.code === code);
  if (!configItem) return '';
  return configItem.desc;
}
export * from './exportData';
export * from './platInfo';
export { default as rolePermissions } from './rolePermissions';
export * from './shopInfo';
export * from './constant';




