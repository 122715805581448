import debounce from './debounce';
import { isObject } from './utils';

/**
 *
 * @since 0.1.0
 * @category Function
 * @param {Function} func The function to throttle.
 * @param {number} [wait=0]
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=true]
 * @param {boolean} [options.trailing=true]
 * @returns {Function} Returns the new throttled function.
 */
function throttle(func, wait, options) {
  let leading = true;
  let trailing = true;

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  if (isObject(options)) {
    leading = 'leading' in options ? !!options.leading : leading;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }
  return debounce(func, wait, {
    leading,
    trailing,
    'maxWait': wait,
  });
}

export default throttle;
