<template>
  <div class="table-page-template-container">
    <div class="search-block">
      <div class="search-left-from-content">
        <div class="search-block-item" v-for="(field, index) in pageOptions.searchFields" :key="index">
          <el-input v-if="field.type === 'text'"
            v-model="pageOptions.searchParams[field.prop]"
            :placeholder="field.placeholder"/>
          <el-select v-if="field.type === 'select'" v-model="pageOptions.searchParams[field.prop]">
            <el-option v-for="(option, pIndex) in field.optionsData"
              :key="pIndex"
              :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="search-right-operate-content">
        <el-button v-for="(btnItem, index) in pageOptions.searchOperate"
          @click="$emit(btnItem.eventName, pageOptions)"
          :key="index">{{btnItem.label}}</el-button>
      </div>
    </div>
    <div class="page-table-container">
      <el-table stripe :data="tableDataList" border style="width: 100%;">
        <template v-for="(field, index) in pageOptions.tableFields">
          <el-table-column :key="index"
            v-if="field.type == 'btns'"
            :fixed="field.isFixed"
            :align="field.align"
            :label="field.placeholder">
            <template slot-scope="scope">
              <el-tag v-for="(btn, bIndex) in field.btns"
                :key="bIndex"
                type="success"
                @click="$emit('test', scope)">{{btn}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :key="index"
            v-else-if="field.type == 'img'"
            :label="field.placeholder">
            <template slot-scope="scope">
              <el-image style="width: 60px; height: 60px"
                :src="scope[field.prop]"
                :preview-src-list="[scope[field.prop]]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :key="index"
            :prop="field.prop"
            :label="field.placeholder"
            show-overflow-tooltip
            :formatter="tableColumnFormatter"
            :align="field.align"
            ></el-table-column>
        </template>
      </el-table>
    </div>
    <div class="page-pagination-container">
      <el-pagination class="" background hide-on-single-page
        :total="pageOptions.searchParams.totalCount"
        :current-page.sync="pageOptions.searchParams.currentPage"
        @current-change="currentPageChangeHandler">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import parseTablePageOptions from './parseTablePageOptions';

export default {
  name: 'TablePageTemplate',
  data() {
    return {
      pageOptions: {
        searchParams: {},
        searchFields: [],
      },
      tableDataList: [],
    };
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(val) {
        this.pageOptions = parseTablePageOptions(val);
        console.log('this.pageOptions', this.pageOptions);
      },
    },
  },
  methods: {
    currentPageChangeHandler() {
      this.fetchTableData();
    },
    tableColumnFormatter(row, column, cellValue, index) {
      const fieldConfig = this.pageOptions.tableFields[index];
      if (fieldConfig.filterFunName && this.pageOptions.methods && this.pageOptions.methods[fieldConfig.filterFunName]) {
        return this.pageOptions.methods[fieldConfig.filterFunName].apply(this, [row, column, cellValue, index, fieldConfig]);
      }
      return cellValue;
    },
    async fetchTableData() {
      const { list, currentPage, totalCount } = await this.pageOptions.getTableData(this.pageOptions.searchParams);
      this.pageOptions.searchParams.currentPage = currentPage;
      this.pageOptions.searchParams.totalCount = totalCount;
      this.tableDataList = list;
    },
  },
  created() {
    this.fetchTableData();
  },
};
</script>

<style scoped>
.table-page-template-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search-block {
  flex-shrink: 0;
  display: flex;
}
.search-block-item {
  width: 180px;
  float: left;
  margin-right: 16px;
}
.search-left-from-content {
  flex: 1;
}
.search-right-operate-content {
  flex-shrink: 0;
}
.page-table-container {
  flex: 1;
  padding-top: 20px;
}
.page-pagination-container {
  flex-shrink: 0;
  padding-top: 30px;
}
</style>
