import { loadAMap } from './loadSdkScript';
import { getUserCurrentAddress } from './getUserLocation';
import bezierCurve from './bezierCurve';

export default class AMapUtils {
  constructor(id) {
    this.map = null;
    this.idStr = id;
    this.markers = [];
    this.drivePathWay = null;
    this.cityFreightText = null;
    this.ridePathWay = null;
    this.polygonMap = null;
    this.district = null;
    this.mark = null;
    this.placeSearch = null;
    this.geocoder = null;
  }

  static loadAMap() {
    return loadAMap();
  }

  setMap(map) {
    this.map = map;
  }

  destroy() {
    if (!this.map) return;
    this.map.destroy();
    this.map = null;
  }

  getMap() {
    return this.map;
  }

  newMap(center, zoom = 12) {
    return loadAMap().then(() => {
      const map = new AMap.Map(this.idStr, {
        center, // 地图中心点
        zoom, // 地图显示的缩放级别
      });
      this.map = map;
      return map;
    });
  }

  cityMap(zoom = 12, options) {
    return loadAMap().then(() => {
      const map = new AMap.Map(this.idStr, {
        resizeEnable: true,
        expandZoomRange: true,
        zooms: [3, 20],
        zoom, // 地图显示的缩放级别
        ...options,
      });
      this.map = map;
      return map;
    });
  }

  /* 驾车路线规划 */
  drivePath(start, end) {
    let shopPos = start;
    let userPos = end;
    if (typeof start === 'string') {
      shopPos = start.split(',');
    }
    if (typeof end === 'string') {
      userPos = end.split(',');
    }
    if (!this.drivePathWay) {
      this.drivePathWay = new AMap.Driving({
        map: this.map,
        policy: 2,
        autoFitView: true,
      });
    }
    return new Promise((resolve, reject) => {
      this.drivePathWay.search(shopPos, userPos, (status, res) => {
        if (status === 'complete') {
          // cb(true, res);
          resolve(res);
        } else {
          reject('路线规划失败');
          // cb(false, '路线规划失败');
        }
      });
    });
  }

  /**
   * 骑行路线规划
   * @param start 开始坐标
   * @param end 结束坐标
   * @returns {Promise<any>}
   */
  ridePath = (start, end) => {
    let shopPos = start;
    let userPos = end;
    if (typeof start === 'string') {
      shopPos = start.split(',');
    }
    if (typeof end === 'string') {
      userPos = end.split(',');
    }
    // const ride = new AMap.Riding({
    //   map: this.map,
    //   policy: 2,
    //   autoFitView: true,
    // });
    if (!this.ridePathWay) {
      this.ridePathWay = new AMap.Riding({
        map: this.map,
        policy: 2,
        autoFitView: true,
      });
    }
    return new Promise((resolve, reject) => {
      this.ridePathWay.search(shopPos, userPos, (status, res) => {
        if (status === 'complete') {
          resolve(res);
        } else {
          reject('路线规划失败');
        }
      });
    });
    // ride
  };

  cityText(point, km) {
    let position = point;
    if (typeof point === 'string') position = point.split(',');
    if (!this.cityFreightText) {
      this.cityFreightText = new AMap.Text({
        text: `距离${km}km`,
        angle: 10,
        position,
        style: {
          'padding': '.2rem 0.4rem',
          'border-radius': '.13rem',
          'background-color': 'white',
          'font-size': '14px',
          'color': '#333',
          'margin-top': '-1.3rem',
        },
      });
    } else {
      this.cityFreightText.setText(`距离${km}km`);
      this.cityFreightText.setPosition(position);
    }
    this.cityFreightText.setMap(this.map);
  }

  /**
   * 提交订单定位文本标注
   * @param point 【数组】或者'lng,lat'
   * @param km 字符串
   */
  orderMapText(point, { template, style }) {
    let position = point;
    if (typeof point === 'string') position = point.split(',');
    if (!this.cityFreightText) {
      const defaultStyle = {
        // 'padding': '.2rem 0.4rem',
        // 'border-radius': '.5rem',
        'background-color': 'transparent',
        'border': 'none',
        // 'font-size': '14px',
        // 'color': '#333',
        'margin-top': '-.4rem',
        'z-index': '202',
      };
      this.cityFreightText = new AMap.Text({
        text: template,
        angle: 0,
        position,
        style: style || defaultStyle,
      });
    } else {
      this.cityFreightText.setText(template);
      this.cityFreightText.setPosition(position);
    }
    this.cityFreightText.setMap(this.map);
  }

  /* 注册点击事件 */
  mapAddEvent(callback) {
    this.map.on('click', (res) => {
      if (callback) {
        callback(res.lnglat.lng, res.lnglat.lat);
      }
    });
  }

  // 定位
  getCurrentAddress(hasMap = false) {
    return new Promise((resolve, reject) => {
      getUserCurrentAddress()
        .then((data) => {
          if (hasMap) {
            this.addPositionMarker(data);
          }
          resolve(data);
        })
        .catch((errMsg) => {
          reject(errMsg);
          // getPositionFileHandler(errMsg, reject);
        });
    });
  }

  addPositionMarker({ lng, lat }) {
    if (!this.map) return;
    const position = [{ lng, lat }];
    this.map.clearMap();
    this.marker(position);
    this.map.setFitView();
  }

  /**
   * 标记店铺
   * @param list
   * @param draggable
   * @param icon
   */
  marker(list, draggable = false, icon) {
    list.forEach((item) => {
      this.mark = new AMap.Marker({
        position: [item.map_lon || item.lng || item.location.lng, item.map_lat || item.lat || item.location.lat],
        offset: new AMap.Pixel(-12, -12),
        zIndex: 201,
        title: item.shop_name || '',
        icon,
        map: this.map,
        draggable,
      });
      const marker = [];
      marker.push(this.mark);
      this.markers = marker;
    });
  }

  addMaker(position, icon, title = '', offsetX = -12, offsetY = -12, draggable = false) {
    return new AMap.Marker({
      position,
      offset: new AMap.Pixel(offsetX, offsetY),
      zIndex: 201,
      title,
      icon,
      map: this.map,
      draggable,
    });
  }

  /**
   * 创建多边形
   * @param path 坐标集，可为数组或者字符串
   * @param fitView 是否地图居中
   * @param draggable 是否拖动
   * @param isEdit 是否绑定编辑，显示，关闭方法
   * @param color 颜色
   */
  polygonCreate(path, fitView = false, draggable = false, isEdit = false, color = '#1791fc') {
    const paths = [];
    path.forEach((item) => {
      if (typeof (item) === 'string') {
        paths.push(item.split(','));
      } else {
        paths.push(item);
      }
    });
    this.polygonMap = new AMap.Polygon({
      path: paths,
      strokeColor: color,
      strokeWeight: 6,
      strokeOpacity: 0.2,
      fillOpacity: 0.4,
      fillColor: color,
      zIndex: 50,
      draggable,
    });
    this.polygonMap.setMap(this.map);
    if (fitView) {
      this.map.setFitView(this.polygonMap);
    }
    if (isEdit) {
      this.polygonMap.modelEditor = new AMap.PolyEditor(this.map, this.polygonMap);
      this.polygonMap.startEdit = () => {
        this.polygonMap.modelEditor.open();
        this.polygonMap.setOptions({ draggable: true });
        return this.polygonMap;
      };
      this.polygonMap.closeEdit = () => {
        this.polygonMap.modelEditor.close();
        this.polygonMap.hide();
        return this.polygonMap;
      };
      this.polygonMap.showModel = () => {
        this.polygonMap.show();
        return this.polygonMap;
      };
    }
  }

  drawCircle(lng, lat, radius, color, strokeColor) {
    const circle = new AMap.Circle({
      center: new AMap.LngLat(lng, lat), // 圆心位置
      radius, // 圆半径
      fillColor: color || '#fa8828', // 圆形填充颜色
      strokeColor: strokeColor || '#fa8828', // 描边颜色
      fillOpacity: 0.4,
      strokeWeight: 2, // 描边宽度
    });
    this.map.add(circle);
    return circle;
  }

  /**
   * 创建以中心点或者地图中心店圆形
   * @param range 圆圈半径
   * @param draggable 是否能拖动
   * @param centerPoint 自定义中心点
   * @param color
   * @returns {*[]} 返回创建的数组
   */
  polyEditorCreate(range, draggable = false, centerPoint, color = '#1791fc') {
    let tag_x;
    let tag_y;
    if (centerPoint && centerPoint.lng && centerPoint.lat) {
      tag_x = centerPoint.lng;
      tag_y = centerPoint.lat;
    } else {
      tag_x = this.map.G.center.lng;
      tag_y = this.map.G.center.lat;
    }
    const base_x = 0.011714;
    const base_y = 0.0089826;
    const distance_x = base_x * range;
    const distance_y = base_y * range;
    const two_third = Math.sqrt(3) / 2;
    const paths = [ // 构建多边形经纬度坐标数组
      [tag_x, tag_y + distance_y],
      [tag_x + (distance_x / 2), tag_y + (distance_y * two_third)],
      [tag_x + (distance_x * two_third), tag_y + (distance_y / 2)],
      [tag_x + distance_x, tag_y],
      [tag_x + (distance_x * two_third), tag_y - (distance_y / 2)],
      [tag_x + (distance_x / 2), tag_y - (distance_y * two_third)],
      [tag_x, tag_y - distance_y],
      [tag_x - (distance_x / 2), tag_y - (distance_y * two_third)],
      [tag_x - (distance_x * two_third), tag_y - (distance_y / 2)],
      [tag_x - distance_x, tag_y],
      [tag_x - (distance_x * two_third), tag_y + (distance_y / 2)],
      [tag_x - (distance_x / 2), tag_y + (distance_y * two_third)],
    ];
    this.polygonCreate(paths, true, draggable, true, color);
    this.polygonMap.startEdit();
    return paths;
  }

  removeMarkers() {
    this.map.remove(this.markers);
    // this.map.clearMap();
  }

  removerPolygonMap() {
    this.map.remove(this.polygonMap);
  }

  /**
   * 清除地图所有内容
   */
  clearMaps() {
    this.map.clearMap();
  }

  /**
   * 获取地图中心点并返回
   * @returns {{tag_y: *, tag_x: *}}
   */
  getCenter() {
    const tag_x = this.map.G.center.lng;
    const tag_y = this.map.G.center.lat;
    return { tag_y, tag_x };
  }

  /**
   * 地图居中
   */
  setFitView() {
    this.map.setFitView();
  }

  /**
   * 根据等级搜索地址
   * @param value 区名称
   * @param district 区域等级，县/区
   * @param color 颜色
   * @returns {Promise<never>|Promise<any>} 返回面积
   */
  searchCity(value, district = 'district', color = '#1791fc') {
    if (!this.district) {
      const opts = {
        subdistrict: 0, // 获取边界不需要返回下级行政区
        extensions: 'all', // 返回行政区边界坐标组等具体信息
        level: 'district', // 查询行政级别为 市
      };
      this.district = new AMap.DistrictSearch(opts);
    }
    if (!value) return Promise.reject();
    this.district.setLevel(district);
    let area = 0;
    return new Promise((resolve, reject) => {
      this.district.search(value, (status, result) => {
        this.clearMaps();
        if (!result.districtList) {
          reject();
          return;
        }
        this.polygonCreate(result.districtList[0].boundaries, true, false, false, color);
        // const mouseTool = new AMap.MouseTool(this.map);
        // result.districtList[0].boundaries;
        AMap.plugin('AMap.GeometryUtil', () => {
          result.districtList[0].boundaries.forEach((item) => {
            area += AMap.GeometryUtil.ringArea(item);
          });
          resolve({
            range: result.districtList[0].boundaries,
            acreage: area,
          });
        });
      });
    });
  }

  /**
   * 搜索地址
   * @param value
   * @returns {string|Promise<any>}
   */
  search(value, cityCode, pageSize = 1, pageIndex = 1) {
    if (value === '') return Promise.reject();
    return new Promise((resolve, reject) => {
      AMap.service(['AMap.PlaceSearch'], () => {
        this.placeSearch = new AMap.PlaceSearch({
          pageSize,
          pageIndex,
          city: cityCode,
        });
        this.placeSearch.search(value, (status, result) => {
          if (status === 'complete') {
            resolve(result.poiList);
          } else if (status === 'no_data') {
            reject('结果为空');
          } else {
            console.log(status, result);
            reject('搜索失败');
          }
        });
      });
    });
  }

  searchNearByPoint = (point, addMarker = true) => new Promise((resolve, reject) => {
    AMap.service(['AMap.PlaceSearch'], () => {
      const placeSearch = new AMap.PlaceSearch({
        pageSize: 10,
        pageIndex: 1,
        // city,
        map: addMarker ? this.map : undefined,
      });
      console.log('serch nearby', point);
      placeSearch.searchNearBy('', point, 1000, (status, res) => {
        if (status === 'complete') {
          resolve(res.poiList);
        } else if (status === 'no_data') {
          reject('结果为空');
        } else {
          reject([]);
        }
      });
    });
  })

  /**
   * 清除搜索地址
   */
  placeSearchClear() {
    console.log(this.placeSearch, 1);
    this.placeSearch.clear();
  }

  /**
   * 根据坐标获取地址name
   * @param item
   * @returns {Promise<any>}
   */
  getAddress(item) {
    const position = [item.map_lon || item.location.lng, item.map_lat || item.location.lat];
    return new Promise((resolve, reject) => {
      AMap.service(['AMap.Geocoder'], () => {
        this.geocoder = new AMap.Geocoder({ ...this.map });
        this.geocoder.getAddress(position, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            // this.platInfo.address = result.regeocode.formattedAddress;
            resolve(result.regeocode.formattedAddress);
          } else {
            reject('地址获取失败');
          }
        });
      });
    });
  }

  /**
   * @param {*} startP 开始经纬度
   * @param {*} endP 结束经纬度
   * @param {*} centerContent 信息弹框展示的内容
   * @param {*} distributionType 订单配送类型 1第三方 2商家自配送
   * @return {*}
   */

  addLine(startP, endP, centerContent, distributionType) {
    const { pathArr, lineCenterPoint } = bezierCurve(startP, endP);
    const centerPoint = lineCenterPoint;// 获取两经纬度 中间经纬度
    if (distributionType === 2) {
      this.addInfoModel(centerPoint, centerContent, -6, -6);// 添加自定义信息框
    }
    if (pathArr.length < 1) return;
    const bz = new AMap.BezierCurve({
      path: pathArr,
      strokeWeight: 10, // 线条宽度
      strokeColor: 'rgba(246,63,41,0.5)', // 线条颜色
      strokeStyle: 'solid', // 线样式
      showDir: true, // 是否显示箭头
    });
    this.map.add(bz);// 绘制两点弧线
  }

  /**
   * @description: 自定义 标记
   * @param {*} position 经纬度
   * @param {*} content 自定义内容
   * @param {*} type 1 用户 2 商家骑手 3中心点样式
   * @return {*}
   */
  customAddMaker(position, content, title = '', offsetX = -30, offsetY = -30, draggable = false) {
    if (!position[0] || !position[1]) {
      return null;
    }
    return new AMap.Marker({
      position,
      offset: new AMap.Pixel(offsetX, offsetY),
      zIndex: 201,
      title,
      content,
      map: this.map,
      draggable,
    });
  }

  addInfoModel(port, content, offsetX = 0, offsetY = 0) {
    // 构建自定义信息窗体
    const infoWindow = new AMap.InfoWindow({
      anchor: 'bottom-center',
      content,
      offset: new AMap.Pixel(offsetX, offsetY),
      retainWhenClose: true,
    });
    infoWindow.open(this.map, port);
    return infoWindow;
  }
}
