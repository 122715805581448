
export default {
  methods: {
    getSupportBussinessDesc(supportBusiness, flag = '+') {
      const desc = [];
      if ((supportBusiness & 1) > 0) desc.push('外卖');
      if ((supportBusiness & 2) > 0) desc.push('团购');
      if ((supportBusiness & 4) > 0) desc.push('堂食');
      if ((supportBusiness & 8) > 0) desc.push('返利');
      return desc.join(flag);
    },
  },
};
