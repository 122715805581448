export const TIME_MSG_INTERVAL = 60; // 发送短信间隔;

export const DEFAULT_DATA_PAGES = 1; // 默认表格数据总条数

let apiBaseUrl = `${location.protocol}//waimai.3cfood.com`;

if (process.env.NODE_ENV === 'development' && process.env.VConsole !== 'vconsole') {
  apiBaseUrl = 'http://waimai.3cfood.com';
}

// base 不再使用  故不再暴露 此常量
// export const SCFOOD_BASE_URL = apiBaseUrl; // 接口地址

let IS_DEPLOYMENT = false; // 是否是独立部署

window.apiBaseUrl = apiBaseUrl;

export const setBaseUrl = (url) => {
  apiBaseUrl = url;
  window.apiBaseUrl = url;
  if (url) {
    IS_DEPLOYMENT = true;
  }
};

// export const getBaseUrl = () => {
//   const href = window.location.href;
//   if (href.indexOf('keloop.cn') > -1) {
//     return apiBaseUrl;
//   }
//   if (href.indexOf('localhost') > -1) {
//     return apiBaseUrl;
//   }
//   // if (!IS_DEPLOYMENT) {
//   //   return 'https://h5.keloop.cn';
//   // }
//   return apiBaseUrl;
// };

export const checkIsDeploy = () => IS_DEPLOYMENT;
