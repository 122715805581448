import axios from 'axios';
import qs from 'qs';

axios.defaults.paramsSerializer = function paramsSerializer(params) {
  return qs.stringify(params);
};

const axiosInstance = axios.create({
  // baseURL: getBaseUrl(),
  // ourConfig: 0,
  timeout: 10000,
});

export const loadAxiosInterceptors = (requestInterceptors, responseInterceptors, options) => {
  // 加载请求拦截器
  requestInterceptors.forEach((item) => {
    let { onFulfilled, onRejected } = item;
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = (config) => config;
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = (error) => Promise.reject(error);
    }
    axiosInstance.interceptors.request.use(
      (config) => onFulfilled(config, options),
      (error) => onRejected(error, options),
    );
  });

  // 加载响应拦截器
  responseInterceptors.forEach((item) => {
    let { onFulfilled, onRejected } = item;
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = (response) => response;
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = (error) => Promise.reject(error);
    }
    axiosInstance.interceptors.response.use(
      (response) => onFulfilled(response, options),
      (error) => onRejected(error, options),
    );
  });
};

export default axiosInstance;
