export default {
  supportBusiness: [
    {
      value: 1,
      label: '外卖',
      flag: 'waimai',
    },
    {
      value: 2,
      label: '团购',
      flag: 'group',
    },
    {
      value: 4,
      label: '堂食',
      flag: 'eatin',
    },
    {
      value: 8,
      label: '返利',
      flag: 'rebate'
    }
  ],
};
