import {
  // isEmpty, setStatusBarColor,
  cookie,
  // deepClone, colorTransformor,
} from '@base/utils';

let userToken;
export const setUserToken = (token) => {
  if (token === 'null' || token === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    token = '';
  }
  userToken = token;
  localStorage.setItem('o2o_user_agent_admin_token', token);
  cookie('agent_admin_jwt_token', token, { path: '/' });
};
export const getUserToken = () => {
  if (userToken) return userToken;
  const cookieToken = cookie('agent_admin_jwt_token');
  const localToken = localStorage.getItem('o2o_user_agent_admin_token');
  if (!cookieToken || !localToken) {
    setUserToken(cookieToken || localToken);
  }
  userToken = cookieToken || localToken;
  if (userToken === 'null' || userToken === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    userToken = '';
  }
  return userToken;
};


// 微信用户登录openid
let userOpenid = localStorage.getItem('o2o_user_openid');
export const setUserOpenid = (openid) => {
  userOpenid = openid;
  localStorage.setItem('shop_user_openid', openid);
};
export const getUserOpenid = () => userOpenid;

let userUnionid = localStorage.getItem('o2o_user_unionid');
export const setUserUnionid = (unionid) => {
  userUnionid = unionid;
  localStorage.setItem('shop_user_unionid', unionid);
};

export const getUserUnionid = () => userUnionid;

export const setUserTokenAndUnionid = (token, unionid) => {
  setUserToken(token);
  setUserUnionid(unionid);
};

export const setUserTokenAndOpenId = (token, openId) => {
  setUserToken(token);
  setUserOpenid(openId);
};
