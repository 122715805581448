// 将一个组件包装为单例 组件

function destroyInstance(instance) {
  instance.$destroy();
  if (instance.$el) {
    instance.$el.parentNode.removeChild(instance.$el);
  }
}

export default (Vue, options = {}) => {
  const {
    targetComponent,
    // vue 原型链上的挂载名称
    prototypeMounteName,
    // body 下的挂载点的div 的 id
    bodyMounteId,
  } = options;
  if (process.env.NODE_ENV === 'development') {
    if (!targetComponent) throw new Error('请传入原组件');
    if (!bodyMounteId) throw new Error('请传入body下的挂载的div的id');
  }

  let instance;

  function singletonPattern(config, events = {}) {
    const mounteId = `__${bodyMounteId}__`;

    if (instance) {
      destroyInstance(instance);
      instance = null;
    }

    let containerElement = document.getElementById(mounteId);
    if (!containerElement) {
      containerElement = document.createElement('div');
      containerElement.id = mounteId;
      document.body.appendChild(containerElement);
    }

    // eslint-disable-next-line
    instance = new targetComponent({
      el: `#${mounteId}`,
      data: config.data || {},
      propsData: config.propsData || {},
    });

    Object.keys(events).forEach((key) => {
      if (process.env.NODE_ENV === 'development') {
        if (!key || !events[key]) throw new Error('请传入的事件类型不合格');
      }
      instance.$on(key, events[key]);
    });

    instance.$once('close', () => {
      destroyInstance(instance);
      instance = null;
    });
  }

  Vue.prototype[prototypeMounteName || targetComponent.name] = singletonPattern;
  return singletonPattern;
};
