import api from './api';
/**
     * @api {post} /general/v1/Auth/getSpreadCountByTel 店长端获取手机号注册了多少平台
     * @apiGroup Auth
     * @apiParam {string} tel 手机号.
     * @apiSuccessExample 成功示例:
     * {
     *  "code": 1000,
     *  "msg": "获取成功",
     *  "data": {
     *       1, //注册平台数量（0表示未注册到任何平台）
     *  }
     * }
     */
export const $sp_getSpreadCountByTel = (params) => api.postNoMessage('/general/v1/Auth/getSpreadCountByTel', params);


// 判断是否在壳子中
export const $sg_checkShell = () => api.get('/api/Common/checkShell');


// 店长pc登录
export const $sp_shopLogin = (params) => api.post('/general/v3/Auth/shopLogin', params);

// 获取登录验证码
export const $sg_getImageVerify = () => api.getNoTips('/general/v1/Auth/getImageVerify');

// 重置密码
export const $sp_getBackPassword = (params) => api.post('/general/v1/Auth/getBackPassword', params);

export const $sp_getSpreadTokenBySpreadTel = (params) => api.postNoMessage('/general/v1/Auth/getSpreadTokenBySpreadTel', params);

// 发送oem短信验证码
export const $sg_sendOemTelCode = (params) => api.get('/general/v3/Auth/sendOemTelCode', params);


// 店铺修改密码
export const $sg_updateShopPassword = (params) => api.post('/mall/v1/ShopManager/updateShopPassword', params);

// 登陆
// export const $sp_login = (params) => api.postNoMessage('/general/v1/Auth/login', params);
export const $sp_login = (params) => api.postNoMessage('/api/agent/biz/agent/login', params);
// /api/agent/biz/agent/updatePassword
export const $p_resetPwd = (params) => api.post('/api/agent/biz/agent/updatePassword', params);
