import Vue from 'vue';
import ExportTipsCom from './ExportTips.vue';

const ExportTips = Vue.extend(ExportTipsCom);
let exportTipsInstance;

export default {
  open(opts = {}) {
    if (exportTipsInstance) {
      exportTipsInstance.data = { ...opts };
    }
    let exportContainer = document.getElementById('exportDialog__');
    if (!exportContainer) {
      exportContainer = document.createElement('div');
      exportContainer.id = 'exportDialog__';
      document.body.appendChild(exportContainer);
    }
    exportTipsInstance = new ExportTips({
      el: '#exportDialog__',
      data: { ...opts },
    });
    Vue.nextTick(() => {
      exportTipsInstance.visible = true;
    });
  },
};
