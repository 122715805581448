
// todo 这个组件需要优化
export default {
  state: {
    isShowExportTips: localStorage.getItem('ExportDataTips') || false, // 下载中心是否显示小红点
    // isShowExportDialog: false, // 是否显示下载提示对话框
    // exportParams: {},
    // exportFlag: '',
  },
  mutations: {
    // 改变小红点显示状态
    changeExportTips(state, val) {
      state.isShowExportTips = val;
    },
    // 改变下载提示对话框显示状态
    // changeExportDialog(state, val) {
    //   state.isShowExportDialog = val;
    // },
  },
  // actions: {
  //   showExportDialog({ state, commit }, params) {
  //     commit('changeExportDialog', true);
  //     state.exportFlag = params.flag;
  //     state.exportParams = {
  //       ...params,
  //       flag: undefined,
  //     };
  //   },
  // },
};
