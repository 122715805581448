import PrevReclick from './lib/prevReClick';
import tablePageTemplate from './lib/table/tablePageTemplate.vue';

export { default as toSingletonPattern } from './lib/toSingletonPattern';


export default {
  install(Vue, options = {}) {
    Vue.use(PrevReclick, options.prevReclick);
    Vue.component(tablePageTemplate.name, tablePageTemplate);
  },
};
