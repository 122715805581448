import Vue from 'vue';
import Vuex from 'vuex';
import { deepClone } from '@base/utils';
import { $sp_queryNoticeCount, $sp_updNotice } from '@/api/other';
import menu from './menu';
import auth from './auth';
import shopInfos from './shopInfo';
import exportData from './exportData';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    menu,
    auth,
    shopInfos,
    exportData,
  },
  state: {
    userName: '',
    userRoleName: '',
    spreadToken: '',
    shopInfo: {},
    chatList: [],
    chatFlag: false,
    chatNowItem: {},
    shopChatConfig: {},
    chatNoReadFlag: false,
    isChatApp: false,
    noticeNowItem: {},
    spreadMainInfo: {},
    spreadAgentToken: '',
    isReadNotice: false,
  },
  getters: {
    getUserFullName(state) {
      if (!state.userName || !state.userRoleName) return '';
      return `${state.userRoleName}:${state.userName}`;
    },
  },
  actions: {
    async updateNotice({ commit }, params) {
      if (params && params.notice_code) {
        await $sp_updNotice(params);
      }
      $sp_queryNoticeCount().then((res) => {
        commit('setReadNotice', res.data);
      });
    },
  },
  mutations: {
    mutations_setSpreadToken(state, token) {
      state.spreadToken = token;
      localStorage.setItem('songshu_agent_spread_token', token);
    },
    mutations_setSpreadMainInfo(state, info) {
      if (!info) return;
      state.spreadMainInfo = deepClone(info);
      if (info.agent_info) {
        state.spreadAgentToken = info.agent_info.agent_token;
      }
    },
    setUserInfo(state, info) {
      state.userName = info.staff_name;
      state.userRoleName = info.role_name;
    },
    setShopInfo(state, info) {
      state.shopInfo = deepClone(info);
    },
    setChatNowItem(state, info) {
      state.chatNowItem = deepClone(info);
    },
    setChatList(state, info) {
      state.chatList = deepClone(info);
    },
    setShopChatConfig(state, info) {
      state.shopChatConfig = deepClone(info);
    },
    setChatListFlag(state, info) {
      const obj = deepClone(info);
      const objFlag = state.chatList.find((item) => item.last_message.to_user === obj.last_message.to_user);
      if (objFlag) {
        state.chatNowItem = objFlag;
      } else {
        state.chatList.push(obj);
        state.chatNowItem = obj;
      }
      state.chatFlag = true;
    },
    setChatFlag(state) {
      state.chatFlag = !state.chatFlag;
    },
    setChatNoReadFlag(state, info) {
      state.chatNoReadFlag = deepClone(info);
    },
    setIsChatApp(state, info) {
      state.isChatApp = deepClone(info);
    },
    setNoticeNowItem(state, info) {
      state.noticeNowItem = deepClone(info);
    },
    setReadNotice(state, info) {
      state.isReadNotice = deepClone(info);
    },
  },
});
