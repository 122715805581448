import api from './api';

// 账目汇总->外送账目
export const $sg_getMallPlatStatistics = (params) => api.get('/statistics/v1/statistics/getMallPlatStatistics', params);
export const $sg_getShopBaseInfo = () => api.get('/mall/v1/ShopManager/getShopBaseInfo');
// 账目汇总->外送账目-明细
export const $sg_getMallShopDetailStatistics = (params) => api.get('/statistics/v1/statistics/getMallShopDetailStatistics', params);
// 平台/店铺获取 商品销量信息
export const $sg_listGoodsSaleStatistics = (params) => api.get('/mall/v1/GoodsSaleSort/listGoodsSaleStatistics', params);
// 店长PC端->外送订单经营统计（比平台端多一个待退款金额）
export const $sg_getShopStatisticsByTime = (params) => api.get('/order/v1/Statistics/getShopStatisticsByTime', params);

// 首页获取店铺折线图统计数据（仅外送）
export const $sg_getShopOrderDailyStatistic = (params) => api.get('/mall/v2/ShopManager/getShopOrderDailyStatistic', params);

// 下载中心列表数据
export const $sg_getExportList = (params) => api.get('/statistics/v2/SpreadExport/getExportList', params);

// 首页获取店铺待处理事项（仅外送）
export const $sg_getShopOnHandStatistics = (params) => api.get('/mall/v2/ShopManager/getShopOnHandStatistics', params);

// 首页获取店铺营业情况统计（仅外送）
export const $sg_getShopIndexStatistics = (params) => api.get('/mall/v2/ShopManager/getShopIndexStatistics', params);

// 首页获取活动列表
export const $sg_getShopActivityList = (params) => api.getNoMessage('/mch/v1/ShopActivity/getShopActivityList', params);
