import FileUtils from './FileUtils';

const nullFun = () => {};

/**
 * 文件上传类
 * 只负责上传 不负责文件的处理
 */
export default class FileUploader {
  static uploadApiFn;

  static addPlatPicture;

  static loadingFn;

  static setFileUploadApis({ uploadApiFn = nullFun, addPlatPicture = nullFun, loadingFn = nullFun }) {
    FileUploader.uploadApiFn = uploadApiFn;
    FileUploader.addPlatPicture = addPlatPicture;
    FileUploader.loadingFn = loadingFn;
  }

  /**
   * 将base64 文件字符串 上传到oss
   * @param {base64Str} stream 文件的base64编码的字符串
   */
  async base64Upload() {
    const res = await FileUploader.uploadApiFn({
      params: {
        scope: this.scope,
        from: 'sxsh',
      },
      data: {
        stream: this.fileBase64String,
      },
    });
    const resp = res.data;
    if (res.code) {
      if (res.code !== 1000) {
        console.error(resp);
        throw Error(resp);
      }
    } else if (resp.code !== 100) {
      console.error(resp);
      throw Error(resp);
    }
    return resp.data || resp;
  }

  /**
   * 将 blob 文件字符串 上传到oss
   */
  async blobUpload() {
    console.log(this.file);
  }

  scope = 'sxsh_detail_pic';

  // 是否将图片加入图片库
  addLibrary = true;

  // 是否压缩 此属性只在base64上传模式下生效
  compress = true;

  width = undefined;

  height = undefined;

  // 需要上传的文件base64字符串
  fileBase64String = undefined;

  file = undefined;

  picture_type;

  constructor(file, {
    width, height, compress = true, scope, addLibrary,
    pictureType = true,
  }) {
    this.file = file;
    this.width = width || 750;
    this.height = height;
    this.compress = compress;
    this.scope = scope;
    this.addLibrary = addLibrary;
    this.pictureType = pictureType;
  }

  async upload(createPictureCallBack) {
    FileUploader.loadingFn(true);
    const fileUtils = new FileUtils(this.file);
    let imgPath;
    if (!this.file.type.startsWith('image')) {
      imgPath = await this.blobUpload();
    } else {
      if (this.compress && this.file.type !== 'image/gif') {
        try {
          this.fileBase64String = await fileUtils.cutImage(this.width, this.height, 0.9);
        } catch (e) {
          this.fileBase64String = await FileUtils.getFileBase64(this.file);
        }
      } else {
        this.fileBase64String = await FileUtils.getFileBase64(this.file);
      }
      imgPath = await this.base64Upload();
    }
    console.log('eeeeeeeeeeeee', imgPath);
    if (this.addLibrary) {
      this.addImgToPicture(imgPath, createPictureCallBack);
    }
    FileUploader.loadingFn(false);
    return imgPath;
  }

  async addImgToPicture(imgPath, createPictureCallBack) {
    await this.addPlatPicture({
      picture_type: this.pictureType,
      img_path: imgPath,
    }).then(createPictureCallBack);
  }
}
