export default (url) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onerror = reject;
  document.head.appendChild(script);
  script.onload = () => {
    // console.log('script onload', url, su);
    resolve('success');
  };
});
