import './logTracker';
import log, { setLoggerOptions } from './putTracker';

const logger = new window.Tracker('cn-hangzhou.log.aliyuncs.com', 'songshu-web', 'client-log');

window.logger = logger;

function exportLog(logObj) {
  if (logObj && logObj.message && logObj.message === 'Script error.') return;
  log.info(logObj);
}

export const initLogReport = (Vue, options = {}) => {
  setLoggerOptions({
    source: options.target,
  });
  // 注册一个vue的异常回调handel
  Vue.config.errorHandler = (err, vm, info) => {
    console.error(err);
    console.log(vm, info);
    log.error(err.name, err.code, err.stack || JSON.stringify(err), err.message || err.msg, info);
  };
};

// promise 异常
window.addEventListener('unhandledrejection', (event) => {
  // console.log(event);
  if (event.reason) {
    log.error(event.type, event.reason);
  }
});

window.onerror = (message, source, lineno, colno, error) => {
  // console.error(message, source, lineno, colno, error);
  const lowCashMessage = message.toLowerCase();
  if (lowCashMessage.indexOf('script error') > -1) {
    return;
  }
  if (lineno === 0 && colno === 0 && !error) {
    return;
  }
  const log_obj = {
    source,
    lineno,
    colno,
  };
  if (error) {
    log_obj.stack = error.stack;
    log_obj.err_message = error.message;
    log_obj.name = error.name;
  }
  log.error(log_obj, message);
};

// 资源加载异常
window.addEventListener('error', (event) => {
  if (event && (event.target instanceof HTMLElement)) {
    // console.log('资源加载异常', event.target.nodeName, event, event.target);
    if (event.target.nodeName === 'IMG') return;
    const target = event.target;
    const log_obj = {
      err_message: '资源加载异常',
      target,
      path: target.src || '',
    };
    log.error(target.src || '', target, '资源加载异常');
  }
}, true);


export default exportLog;
