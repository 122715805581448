import Vue from 'vue';
import 'normalize.css';
import './global/globalBootstrap';
import { initLogReport } from '@base/utils/logger/logger';
import './style/index.scss';
import './assets/iconfont.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element';
import globalMethods from './global/globalMethods';
import gloablMixin from './global/gloablMixin';
import globalComponents from './global/globalComponents';

initLogReport(Vue, {
  target: 'o2o-agent-admin',
});

Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
  Vue.config.performance = true;
}

Vue.use(globalMethods);

Vue.use(globalComponents);

Vue.mixin(gloablMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
