const computyError = () => ({
  pathArr: [],
  lineCenterPoint: [],
});

const pointToNumber = (point) => {
  point.forEach((item, index) => {
    if (typeof item === 'string') {
      const num = Number(item);
      // eslint-disable-next-line
      point[index] = isNaN(num) ? 0 : num;
    }
  });
};

export default (startPoint, endPoint) => {
  if (!startPoint || !endPoint) return computyError();
  if (endPoint.length !== 2 || startPoint.length !== 2 || endPoint[0] === undefined) return computyError();
  pointToNumber(startPoint);
  pointToNumber(endPoint);
  console.log(startPoint, endPoint);

  // const lineLength = Math.sqrt((startPoint[0] - endPoint[0]) ** 2 + (startPoint[1] - endPoint[1]) ** 2);

  // const scHeight = lineLength / (2 * Math.sqrt(3));

  const lineCenterPoint = [(startPoint[0] + endPoint[0]) / 2, (startPoint[1] + endPoint[1]) / 2];

  const step = 0.001;
  const tan = (startPoint[1] + endPoint[1]) / (startPoint[0] + endPoint[0]);

  let controlerPointX = lineCenterPoint[0] + step;
  let controlerPointY = lineCenterPoint[1] + (step * tan);

  const lineDistance = Math.sqrt((startPoint[0] - endPoint[0]) ** 2 + (startPoint[1] - endPoint[1]) ** 2);

  console.log(tan, 'tan');

  if (lineDistance < 0.1) {
    // 如果商家和用户的距离很近 则不显示 线
    return {
      pathArr: [
        startPoint,
        endPoint,
      ],
      lineCenterPoint,
    };
  }


  let maxAdd = 100;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const toCenterDistance = Math.sqrt((controlerPointX - lineCenterPoint[0]) ** 2 + (controlerPointY - lineCenterPoint[1]) ** 2);
    if (toCenterDistance > (lineDistance * 0.3)) break;
    controlerPointX += step;
    controlerPointY += (step * tan);
    maxAdd -= 1;

    if (maxAdd < 0) return computyError();
  }

  const pathArr = [
    startPoint,
    [controlerPointX, controlerPointY, endPoint[0], endPoint[1]],
  ];
  // pathArr.push(centerPoint);
  // pathArr.push(endPoint);
  console.log(JSON.stringify(pathArr));
  return {
    pathArr,
    lineCenterPoint: [(controlerPointX + lineCenterPoint[0]) / 2, (controlerPointY + lineCenterPoint[1]) / 2],
    // lineCenterPoint: [controlerPointX, controlerPointY],
  };
};
