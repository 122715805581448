import axios from 'axios';
import {
  // setSpreadToken,
  getBaseUrl,
} from '@/config';
import { getUserToken } from '@/global/tokenManager';


// exportFileSync
export default (url, params, methods = 'get', defaultFileName, process) => {
  const request = axios.create({
    baseURL: getBaseUrl(),
  });

  request.interceptors.request.use((config) => {
    if (!config.headers) {
      // eslint-disable-next-line
      config.headers = {};
    }
    if (!config.headers.Authorization) {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${getUserToken()}`;
    }
    return config;
  });

  let methodsRe;
  if (methods === 'get') {
    methodsRe = request.get(url, {
      params,
      responseType: 'arraybuffer',
      onDownloadProgress(e) {
        process && process(e);
      }, 
    });
  } else {
    methodsRe = request.post(url, params, { responseType: 'arraybuffer' });
  }

  return methodsRe.then((resp) => {
    let fileName = defaultFileName;
    if (resp.headers['content-disposition']) {
      console.log('xxixi');
      const disposition = resp.headers['content-disposition'];
      let nameStartIndex = disposition.indexOf('fileName=');
      if (nameStartIndex === -1) {
        nameStartIndex = disposition.indexOf('filename=');
      }
      fileName = decodeURI(disposition.substring(nameStartIndex + 9, disposition.length));
    }
    // const fileName = 'ddd.zip';
    let blob;
    if (navigator.msSaveBlob) {
      // IE 10+
      blob = navigator.msSaveBlob(new Blob([resp.data], { type: resp.headers['content-type'] }), fileName);
    } else {
      blob = new Blob([resp.data], { type: resp.headers['content-type'] });
    }
    console.log(resp, blob);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (e) => {
      const a = document.createElement('a');
      a.download = fileName;
      // 后端设置的文件名称在res.headers的 "content-disposition": "form-data; name=\"attachment\"; filename=\"20181211191944.zip\"",
      a.href = e.target.result;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 200);
    };
  });
};
