import throttle from './base/throttle';

// 获取窗口可视范围的高度
export const getClientHeight = () => {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight)
      ? document.body.clientHeight
      : document.documentElement.clientHeight;
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight)
      ? document.body.clientHeight
      : document.documentElement.clientHeight;
  }
  return clientHeight;
};

export const getClientWidth = () => {
  let clientWidth = 0;
  if (document.body.clientWidth && document.documentElement.clientWidth) {
    clientWidth = (document.body.clientWidth < document.documentElement.clientWidth)
      ? document.body.clientWidth
      : document.documentElement.clientWidth;
  } else {
    clientWidth = (document.body.clientWidth > document.documentElement.clientWidth)
      ? document.body.clientWidth
      : document.documentElement.clientWidth;
  }
  return clientWidth;
};

export const getScrollTop = () => {
  let scrollTop = 0;
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop;
  } else if (document.body) {
    scrollTop = document.body.scrollTop;
  }
  return scrollTop;
};

export const getScrollHeight = () => Math.max(document.body.scrollHeight,
  document.documentElement.scrollHeight);

export const getDocumentToBottomHeight = () => {
  // 客户端高度
  const height = getClientHeight();
  // 页面滚动高度
  const theight = getScrollTop();

  const rheight = getScrollHeight();
  return rheight - theight - height;
};

const elementCache = {};

// 因为页面更新后描点的a标签不会被销毁，仍然可以被引用，所以需要启动一个定时器去更新element缓存
// 如果苗点元素不存在则直接更新缓存
setInterval(() => {
  // console.time('updateElemetCache');
  Object.keys(elementCache).forEach((name) => {
    if (!elementCache[name]) return;
    elementCache[name] = document.querySelector(`a[name=${name}]`);
  });
  // console.timeEnd('updateElemetCache');
}, 1000);

export const getElementToBottomHeight = (name) => {
  let elem;
  if (elementCache[name]) {
    elem = elementCache[name];
  } else {
    elem = document.querySelector(`a[name=${name}]`);
    elementCache[name] = elem;
  }
  if (!elem) return null;
  const elementTop = elem.offsetTop;
  // 客户端高度
  const height = getClientHeight();
  // 页面滚动高度
  const theight = getScrollTop();
  // 页面已经展示过的高度，滚动高度 + 客户端高度
  const showHeight = theight + height;

  return elementTop - showHeight;
};

export const getElementToTopHeight = (name) => {
  let elem;
  if (elementCache[name]) {
    elem = elementCache[name];
  } else {
    elem = document.querySelector(`a[name=${name}]`);
    elementCache[name] = elem;
  }
  if (!elem) return null;
  const elementTop = elem.offsetTop;

  return elementTop - getScrollTop();
};

// window.onscroll = function () {
//   // 窗口可视范围的高度
//   var height = getClientHeight(),
//     // 窗口滚动条高度
//     theight = getScrollTop(),
//     // 窗口可视范围的高度
//     rheight = getScrollHeight(),
//     // 滚动条距离底部的高度
//     bheight = rheight - theight - height;
//
//   document.getElementById('show').innerHTML =
//     '此时浏览器可见区域高度为：' + height +
//     '<br />此时文档内容实际高度为：' + rheight +
//     '<br />此时滚动条距离顶部的高度为：' + theight +
//     '<br />此时滚动条距离底部的高度为：' + bheight;
// }

const eventHandelCache = {};


export const unbindScrollEvent = (page) => {
  // document.removeEventListener('scroll', eventHandelCache[page]);
  eventHandelCache[page] = undefined;
};

const emitGlobalEventHandler = () => {
  const documentToBottomHeight = getDocumentToBottomHeight();
  // console.log('scroll hanler', documentToBottomHeight);
  Object.keys(eventHandelCache).forEach((name) => {
    if (!eventHandelCache[name]) return;
    if (eventHandelCache[name].toTop) {
      // 如果是到顶部的高度
      const toTopHeight = getElementToTopHeight();
      const hander = eventHandelCache[name].handler;
      hander && hander(toTopHeight, eventHandelCache[name].distance);
      return;
    }
    let toBottomHeight;
    if (eventHandelCache[name].isName) {
      toBottomHeight = getElementToBottomHeight(name);
    } else {
      toBottomHeight = documentToBottomHeight;
    }
    if (toBottomHeight === null) {
      // 如果锚点元素不存在，则解绑滚动事件
      // unbindScrollEvent(name);
      return;
    }
    if (toBottomHeight < eventHandelCache[name].distance) {
      const hander = eventHandelCache[name].handler;
      // distance 到页面底部的距离 ， isEnd，是否是滑动到结束
      hander && hander(toBottomHeight, documentToBottomHeight <= 0); // eslint-disable-line
    }
  });
};

let isBindEvent = false;
// 对handler的触发采用延时处理
const throttleEventHandler = throttle(emitGlobalEventHandler, 300);
// let eventStartX;
let eventStartY;

function initGlobalEvent() {
  document.addEventListener('scroll', throttleEventHandler);

  document.addEventListener('touchstart', (e) => {
    // console.log(e);
    if (getDocumentToBottomHeight() === 0) {
      // eventStartX = e.originalEvent.changedTouches[0].pageX;
      eventStartY = e.touches[0].pageY;
    } else {
      // eventStartX = undefined;
      eventStartY = undefined;
    }
  });
  document.addEventListener('touchmove', (e) => {
    // console.log(e);
    if (!eventStartY) return;
    // const moveEndX = e.originalEvent.changedTouches[0].pageX;
    const moveEndY = e.touches[0].pageY;
    // const X = moveEndX - eventStartX;
    const Y = moveEndY - eventStartY;

    if (Y < 25) {
      // console.log(Y);
      throttleEventHandler(e);
    }

    // if (X > 0) {
    //   alert('left 2 right');
    // } else if (X < 0) {
    //   alert('right 2 left');
    // } else if (Y > 0) {
    //   alert('top 2 bottom');
    // } else if (Y < 0) {
    //   alert('bottom 2 top');
    // } else {
    //   alert('just touch');
    // }
  });

  isBindEvent = true;
}

export const bindScrollEvent = (page, handler, distance = 50, toTop = false) => {
  if (eventHandelCache[page]) {
    console.warn(`${page}已经绑定过事件`);
  }
  eventHandelCache[page] = {
    distance,
    handler,
    name: page,
    isName: false,
    toTop,
  };
  // console.log('ssss', eventHandelCache);
  if (!isBindEvent) {
    initGlobalEvent();
  }
};

export const bindScrollerByNameEvent = (name, hander, distance = 50, toTop = false) => {
  if (eventHandelCache[name]) {
    console.warn(`${name}已经绑定过事件`);
  }
  eventHandelCache[name] = {
    distance,
    handler: hander,
    name,
    isName: true,
    toTop,
  };
  if (!isBindEvent) {
    initGlobalEvent();
  }
};


function scrollToEndedtHanlder(event) {
  if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
    const paths = event.path;
    if (!paths) {
      return;
    }
    let scrollTopDistance = 140;
    const needScroll = paths.some((step) => {
      if (!step.hasAttribute) return false;
      const has = step.hasAttribute('focus-top');
      if (has) {
        scrollTopDistance = Number(step.getAttribute('focus-top'));
      }
      return has;
    });
    if (needScroll) {
      console.log('scrollIntoView', Boolean(event.target.scrollIntoView), Boolean(event.target.scrollIntoViewIfNeeded));
      try {
        if (event.target.scrollIntoView) {
          event.target.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
          return;
        } else if (event.target.scrollIntoViewIfNeeded) {
          event.target.scrollIntoViewIfNeeded(true);
          return;
        }
      } catch (e) {
        console.log(e);
      }
      const elemToClientTop = event.target.getBoundingClientRect().top;
      const scrollTopHeight = getScrollTop();
      document.documentElement.scrollTop = (elemToClientTop + scrollTopHeight) - scrollTopDistance;
    }
  }
}

let isBindScrollTopEvent = false;
export const bindInputClickScrollTopEvent = () => {
  if (isBindScrollTopEvent) return;
  document.addEventListener('focus', scrollToEndedtHanlder, true);
  isBindScrollTopEvent = true;
};

export const unbindInputClickScrollTopEvent = () => {
  document.removeEventListener('focus', scrollToEndedtHanlder, true);
  isBindScrollTopEvent = false;
};

// 断言一个元素是否在当前显示窗口的内，包括在上方
export const assertElemIsVisible = (name, distance = 0) => {
  const toBottomHeight = getElementToBottomHeight(name);
  // console.log('toBottomHeight', toBottomHeight, name, elementCache[name], document.querySelector(`a[name=${name}]`));
  return distance > toBottomHeight;
};


let bodyTouchStartLastY;
let bodyEventIsBind = false;
const touchmoveHandler = (event) => {
  const currentY = event.changedTouches[0].clientY;
  const { scrollTop } = document.body;
  const toTop = scrollTop <= 0;
  if (currentY > bodyTouchStartLastY && toTop) {
    console.log('body scroll top disable');
    event.preventDefault();
  }
  if (currentY < bodyTouchStartLastY) {
    const height = document.body.querySelector('a[name=global_height_target_name]').offsetTop;
    const maxtop = height - getClientHeight();
    if (scrollTop >= maxtop) {
      console.log('body scroll bottom disable');
      event.preventDefault();
    }
  }
};

const touchstartHandler = (event) => {
  bodyTouchStartLastY = event.changedTouches[0].clientY;
};

export const disableBodyScrollTop = () => {
  if (bodyEventIsBind) return;
  bodyEventIsBind = true;
  const aName = document.createElement('a');
  aName.name = 'global_height_target_name';
  document.body.appendChild(aName);
  document.body.addEventListener('touchstart', touchstartHandler);
  document.body.addEventListener('touchmove', touchmoveHandler);
};
