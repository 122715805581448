/**
 * 防止重复点击的组件
 * 权限校验组件
 * 如需关闭事件劫持 则将 events 传空字符串
 * 初始化是需要传入参数 validateUserRights
 */

export default {
  install(Vue, options = {}) {
    Vue.component('PrevReclick', {
      abstract: true,
      props: {
        time: {
          type: [String, Number],
          default: 2000,
        },
        events: {
          type: String,
          default: 'click',
        },
        /**
         * 权限校验相关 参数配置
         * 多个使用逗号分割
         */
        rights: {
          type: String,
          default: '',
        },
        /**
         * 权限校验模式
         * every 权限必须全部具备再显示 子组件
         * some 权限只要具备其中一个就显示 子组件
         */
        rightsModel: {
          type: String,
          default: 'every',
        },
      },
      created() {
        if (this.rights && !options.validateUserRights(this.rights, this.rightsModel)) {
          // 如果不具备权限 直接 后面的逻辑就不需要再走了
          this.noRights = true;
          return;
        }
        this.eventKeys = this.events.split(',');
        this.originMap = {};
        this.debouncedMap = {};
        this.lastTriggerTime = new Date().getTime() - (+this.time);
      },



      render() {
        if (this.noRights) return null;
        if (!this.$slots.default) return null;

        // console.log(this, +this.time);
        const getEventMiddleware = (callback) => (...params) => {
          const nowTime = new Date().getTime();
          if ((nowTime - this.lastTriggerTime) < +this.time) {
            return;
          }
          this.lastTriggerTime = nowTime;
          callback.apply(this, params);
        };
        const vnode = this.$slots.default[0];

        this.eventKeys.forEach((key) => {
          if (!key) return;
          let listeners;
          if (vnode.componentOptions) {
            listeners = vnode.componentOptions.listeners;
          } else {
            listeners = vnode.data.on;
          }

          if (!listeners || !listeners[key]) return;

          const target = listeners[key];

          if (target === this.originMap[key] && this.debouncedMap[key]) {
            listeners[key] = this.debouncedMap[key];
          } else if (target) {
            this.originMap[key] = target;
            this.debouncedMap[key] = getEventMiddleware(target);
            listeners[key] = this.debouncedMap[key];
          }
        });



        return vnode;
      },
    });
  },
};
