import { favorable } from '@/config';

export default {
  state: {
    /*
    店铺类别
    type_id (对应店铺字段 :shop_type) 店铺类型 1:美食，2:生活服务,3:休闲娱乐,4:丽人,5:周边游
    type_name  分类名称
    type_module   分类显示形状 1:条形显示,2:块状显示
    child (对应店铺字段 :shop_type_child) 店铺二级分类
    child.child_id 二级分类id
    child.child_name 二级分类名称
    */
    classification: [],
    favorable, // 菜品标签
    goodsSort: [], // 菜品分类
  },
  getters: {
    classification: (state) => state.classification,
    favorable: (state) => state.favorable,
    goodsSortAll: (state) => state.goodsSort,
    goodsSortShow: (state) => state.goodsSort.filter((sort) => sort.state === 1),
  },
  mutations: {
    classification(state, value) {
      state.classification = value;
    },
    favorable(state, value) {
      state.favorable = value;
    },
    goodsSort(state, value) {
      state.goodsSort = value;
    },
  },
};
