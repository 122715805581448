/* eslint-disable */
export default {
  datas: [
    {
      title: {name: '店铺管理', 'key': '1'},
      list: [
        {name: '店铺信息', 'key': '10'},
        {name: '店铺图片', 'key': '11'},
        {name: '外送配置', 'key': '12'},
        {name: '发票设置', 'key': '13'}
      ]
    },
    {
      'title': {'name': '商品管理', 'key': '2'},
      'list': [
        {'name': '商品分类', 'key': '20'},
        {'name': '添加商品', 'key': '21'}
      ]
    },
    {
      'title': {'name': '团购管理', 'key': '3'},
      'list': [
        {'name': '团购商品', 'key': '30'},
        {'name': '团购验券', 'key': '31'},
        {'name': '团购订单', 'key': '32'}
      ]
    },
    {
      'title': {'name': '营销管理', 'key': '4'},
      'list': [
        {'name': '创建限时限量活动', 'key': '40'},
        {'name': '创建店铺优惠券', 'key': '41'}
      ]
    },
    {
      'title': {'name': '顾客留言', 'key': '5'},
      'list': [
        {'name': '查看顾客留言', 'key': '50'},
        {'name': '回复顾客留言', 'key': '51'},
        {'name': '屏蔽顾客留言', 'key': '52'}
      ]
    },
    {
      'title': {'name': '统计结算', 'key': '6'},
      'list': [
        {'name': '财务概览', 'key': '60'},
        {'name': '财务结算', 'key': '61'}
      ]
    }
  ]
}
