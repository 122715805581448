import md5 from 'md5';
import { deepClone, random } from './base/utils';

const addKey = '@%%@ww&&@#I#@';
export default function smsUtil(params) {
  const tokenParams = deepClone(params);
  const stamp = parseInt(new Date().getTime() / 1000, 10);
  tokenParams.timestamp = stamp;
  tokenParams.once = md5(`A${random()}`);
  // const tokenStr = `mess_type=${tokenParams.mess_type}&once=${tokenParams.once}&tel=${tokenParams.tel}&timestamp=${tokenParams.timestamp}`;
  // console.log(`${params.phone}${params.appName}${addKey}`, md5(`${params.phone}${params.appName}${addKey}`));
  tokenParams.sign = md5(`${params.phone}${params.appName}${addKey}`).toUpperCase();
  return tokenParams;
}
