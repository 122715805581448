import { picConfig } from '@/config';
import FileUtils from './FileUtils';
import { noop } from '..';



export class UploadFile {
  constructor(uploadApiFn) {
    this.uploadApiFn = uploadApiFn;
  }

  async send(stream, scope) {
    const params = {
      params: {
        scope,
        from: '3cfood',
      },
      data: {
        stream,
      },
    };
    const res = await this.uploadApiFn(params);
    const resp = res.data;
    if (res.code) {
      if (res.code !== 1000) {
        console.error(resp);
        throw Error(resp);
      }
    } else if (resp.code !== 100) {
      console.error(resp);
      throw Error(resp);
    }
    return resp.data || resp;
  }
}


/**
 * 上传图片
 * @param {options} :
 *  {stream 图片url} |
 *  {
 *    image | file  图片 | 图片文件
 *    width 宽
 *    height 高
 *    quality 压缩比例
 *    imageType 图片类型
 *    scope lingdianit scope
 *  }
 *  type 图片类型
 * @return {Promise} data
 */
export default class UploadImgCom extends UploadFile {
  static defaultOptions = {
    scope: 'food_detail_pic',
    width: 640,
    imageType: 'image/jpeg',
    mini: true,
  }

  constructor(options, {
    uploadApiFn = noop,
    createPlatPicture = noop,
    loadingFn = noop,
  }) {
    super(uploadApiFn);
    // 如果config里配的有的类型, 用config里的覆盖掉options;
    this.defaultConfig = picConfig[options.type];
    this.options = { ...UploadImgCom.defaultOptions, ...options, ...this.defaultConfig };
    this.createPlatPicture = createPlatPicture;
    this.loadingFn = loadingFn;
  }

  // 上传到零点it
  async upload(createPictureCallBack) {
    this.loadingFn(true);
    await this.getImageStream();
    const { stream, scope } = this.options;
    const imgPath = await this.send(stream, scope);
    this.addImgToPicture(imgPath, createPictureCallBack);
    this.loadingFn(false);
    return imgPath;
  }

  addImgToPicture(imgPath, createPictureCallBack) {
    // 如果是config里的图片类型, 则自动上传到平台图库
    if (this.defaultConfig) {
      const paramsTemp = {
        picture_type: this.defaultConfig.picture_type,
        img_path: imgPath,
      };
      this.createPlatPicture(paramsTemp).then(createPictureCallBack);
    }
  }

  // 获取图片流
  async getImageStream() {
    const {
      stream, image, file, mini,
    } = this.options;
    if (stream) {
      return;
    }
    // console.log(file);
    if (image) {
      this.imgToUrl();
      return;
    }
    if (file && (file.type === 'image/gif' || mini === false)) {
      const result = await FileUtils.getFileBase64(file);
      this.options.stream = result;
    } else {
      await this.fileToUrl();
    }
  }

  imgToUrl() {
    const {
      image, width, height, quality, imageType,
    } = this.options;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height || width * (image.height / image.width);

    // 在canvas绘制前填充白色背景
    context.fillStyle = '#fff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // 绘制图片
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    this.options.stream = canvas.toDataURL(imageType, quality);
    return this.options.stream;
  }



  fileToUrl() {
    const { file } = this.options;
    return FileUtils.getFileBase64(file).then((result) => FileUtils.Base64ToImage(result)).then((image) => {
      this.options.image = image;
      return this.imgToUrl();
    });
  }

  // 获取图片的信息
  getImgInfo() {
    const { file } = this.options;
    return FileUtils.getFileBase64(file).then((result) => FileUtils.Base64ToImage(result));
  }

  /** **********************裁切并上传图片********************************* */
  /**
   ** 根据规定的比例动态改变改变宽高值
   * @param {max：图片的最大比例}
    * @param {min: 图片最小比例}
    * */
  changeWidthAndHeight(max, min) {
    return new Promise((resolve) => {
      this.getImgInfo().then((res) => {
        this.options.image = res;
        let width;
        let height;
        const imgRadio = res.width / res.height;
        console.log('图片的宽高', res.width, res.height);
        if (imgRadio < min) {
          width = res.width;
          height = res.width / min;
          // this.options.quality=min;
        } else if (imgRadio >= min && imgRadio <= max) {
          width = res.width;
          height = res.height;
          // this.options.quality=res.width/res.height;
        } else {
          width = res.height * max;
          height = res.height;
          // this.options.quality=max;
        }
        this.options.width = Math.floor(width);
        this.options.height = Math.floor(height);
        console.log('转换后的高度', width, height);
        resolve();
      });
    });
  }

  //  裁切图片
  cutImgToUrl() {
    const {
      image, width, height, quality, imageType,
    } = this.options;
    const canvas = document.createElement('canvas');
    const contexts = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height || width * (image.height / image.width);

    // 在canvas绘制前填充白色背景
    // contexts.fillStyle = '#fff';
    contexts.fillRect(0, 0, canvas.width, canvas.height);

    // 绘制图片
    contexts.drawImage(image, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
    this.options.stream = canvas.toDataURL(imageType, quality);
  }

  // 图片裁剪上传返回图片的宽高
  async uploadMore(createPictureCallBack) {
    const {
      width, height,
    } = this.options;
    this.loadingFn(true);
    this.cutImgToUrl();
    const { stream, scope } = this.options;
    const imgPath = await this.send(stream, scope);
    this.addImgToPicture(imgPath, createPictureCallBack);
    this.loadingFn(false);
    return {
      src: imgPath,
      width,
      height,
    };
  }
}
