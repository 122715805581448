<template>
  <div class="empty-data-page">
    <img class="empty-img" v-if="src" :src="src">
    <p>{{text || '暂无数据'}}</p>
  </div>
</template>
<script>
export default {
  name: 'EmptyData',
  props: {
    src: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
.empty-data-page {
  text-align: center;
  padding-top: 240px;
  & > img {
    min-height: 260px;
    width: 268px;
    height: 281px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
  }
}
</style>
